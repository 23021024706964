import React, { useEffect, useState } from "react";
import { Carousel as BootstrapCarousel } from "react-bootstrap";
import { addEnquiry } from "../services/enquiry";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { getAllProducts } from "../services/product";
import { getAllPly, plySpecArr } from "../services/Ply";
import { getAllglobalConstraints } from "../services/globalConstraints";
import { getAllCategory } from "../services/category";
import { getAllBanner } from "../services/Banner";
import { addEcomEnquiry } from "../services/EcomEnquiry";
import "react-multi-carousel/lib/styles.css";
import slider1 from "../assets/images/banner/slider1.png";
import slider2 from "../assets/images/banner/slider2.png";
import slider3 from "../assets/images/banner/slider3.png";
import slider4 from "../assets/images/banner/slider4.png";
import google_review_img from "../assets/images/google-review.gif";
import google_review_img_1 from "../assets/images/google-review-2.gif";
import banner_video from "../assets/images/video/jsh-packaging.mp4";
import SimpleImageSlider from "react-simple-image-slider";

import { url } from "../services/url";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

export default function Home() {
  const [productArr, setProductArr] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState("");
  const responsive = {
    0: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    760: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    986: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1020: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  };
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(0);
  const [stickerwidth, setStickerWidth] = useState(0);
  const [stickerlength, setStickerLength] = useState(0);
  const [upperLimit, setUpperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [imageArr, setImageArr] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [plyArr, setPlyArr] = useState([]);
  const [selectedPly, setSelectedPly] = useState("");
  const [selectedSpecVirgin, setSelectedSpecVirgin] = useState("");
  const [selectedSpecSemi, setSelectedSpecSemi] = useState("");
  const [selectedNoOfSpec, setSelectedNoOfSpec] = useState("");
  const [selectedNoOfSpecSemi, setSelectedNoOfSpecSemi] = useState("");

  const [selectedProduct, setSelectedProduct] = useState("");

  const [globalConstraintsObj, setGlobalConstraintsObj] = useState({});
  const [categoryArr, setCategoryArr] = useState([]);
  const [companyName, setCompanyName] = useState("");

  const plySpecsArr = plySpecArr;

  const [plyDisplayArr, setPlyDisplayArr] = useState([]);
  const [specVirginDisplayArr, setSpecVirginDisplayArr] = useState([]);
  const [specSemiDisplayArr, setSpecSemiDisplayArr] = useState([]);
  const [banner, setBanner] = useState([]);

  const roundTill2points = (value) => {
    return Math.round(value * 10000) / 10000;
  };

  const getQuote = () => {
    let L = parseInt(length);
    let W = parseInt(width);
    let H = parseInt(height);
    // console.log(L, W, H);
    let A = roundTill2points((W + H + 15) / 25.4);
    let A_round = Math.ceil(A);
    // console.log(A, A_round, "A,A_round");
    let B = roundTill2points((2 * L + 2 * W + 80) / 25.4);
    let B_round = Math.ceil(B);
    // console.log(B, B_round, "B,B_round");

    let ply = 3;
    if (selectedPly) {
      ply = parseInt(selectedPly);
    }

    let specVirgin = 180;
    if (selectedSpecVirgin) {
      specVirgin = parseInt(selectedSpecVirgin);
    }

    let NoOfSpec = 2;
    if (selectedNoOfSpec) {
      NoOfSpec = parseInt(selectedNoOfSpec);
    }

    let plySpecCheck = plySpecsArr.find((el) => el.ply == ply);
    if (plySpecCheck) {
      NoOfSpec = plySpecCheck.specVirgin;
    }
    let global_cost_virgin = 48;
    if (
      globalConstraintsObj.costVirgin &&
      !isNaN(globalConstraintsObj.costVirgin)
    ) {
      global_cost_virgin = parseInt(globalConstraintsObj.costVirgin);
    }

    let cost_virgin = roundTill2points(
      ((A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97) *
        global_cost_virgin
    );
    console.log(cost_virgin, "cost_virgin");
    let semiSpec = 120;
    if (selectedSpecSemi) {
      semiSpec = parseInt(selectedSpecSemi);
    }
    let numberOfSpecSemi = 1;
    // console.log(selectedNoOfSpecSemi, "selectedNoOfSpecSemi");
    if (selectedNoOfSpecSemi) {
      numberOfSpecSemi = parseInt(selectedNoOfSpecSemi);
    }
    if (plySpecCheck) { 
      numberOfSpecSemi = plySpecCheck.specSemi;
    }
    // semiSpec = semiSpec * numberOfSpecSemi
    let semiSpecUsed = semiSpec * 1.5;

    let global_cost_semi = 41;
    if (
      globalConstraintsObj.costSemi &&
      !isNaN(globalConstraintsObj.costSemi)
    ) {
      global_cost_semi = parseInt(globalConstraintsObj.costSemi);
    }
    let cost_semi = roundTill2points(
      ((A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97) *
        global_cost_semi
    );
    console.log(cost_semi, "cost_semi");
    let grossWeight = roundTill2points(
      (A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97 +
        (A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97
    );
    console.log(grossWeight, "grossWeight");
    let totalCostPaper = roundTill2points(cost_semi + cost_virgin);
    console.log(totalCostPaper, "totalCostPaper");
    let conversionCost = roundTill2points(grossWeight * 9.5);
    console.log(conversionCost, "conversionCost");
    let totalCost = totalCostPaper + conversionCost;
    console.log(totalCost, "totalCost");
    let finalCost = totalCost;
    finalCost = Math.ceil(finalCost);

    console.log(finalCost, "finalCost");
    let percentage = (5 / 100) * finalCost;
    console.log(percentage, "percentage");
    setLowerLimit(roundTill2points(finalCost));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let obj = {
        // userId: loggedInUser._id,
        // productId: productObj._id,
        name,
        email,
        phone,
        message,
        length: checkSelectedCategory()
          ? parseInt(length)
          : parseInt(stickerlength),
        breadth: checkSelectedCategory()
          ? parseInt(width)
          : parseInt(stickerwidth),
        height: checkSelectedCategory() ? parseInt(height) : 0,
        ply: selectedPly ? selectedPly : 3,
        specVirgin: selectedSpecVirgin ? selectedSpecVirgin : 180,
        semiSpec: selectedSpecSemi ? selectedSpecSemi : 120,
        // NoOfSpec: selectedNoOfSpec ? selectedNoOfSpec : 2,
        // numberOfSpecSemi: selectedNoOfSpecSemi ? selectedNoOfSpecSemi : 1,
        isBox: checkSelectedCategory(),
        company: companyName,
      };
      let { data: res } = await addEnquiry(obj);
      console.log("this is res>>", res);
      if (res) {
        Swal.fire({
          title: "Success",
          type: "success",
          icon: "success",
          text: `${res.message}`,
        });
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setWidth(0);
        setHeight(0);
        setLength(0);
        setUpperLimit(0);
        setLowerLimit(0);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  const handleEcomEnquiry = async (e) => {
    e.preventDefault();

    try {
      let obj = {
        productName: "",
        range: 0,
        name,
        email,
        phone,
        message,
        company: companyName,
      };
      console.log(obj, "sent obj");
      let { data: res } = await addEcomEnquiry(obj);
      console.log("this is res>>", res);
      if (res) {
        Swal.fire({
          title: "Success",
          type: "success",
          icon: "success",
          text: `${res.message}`,
        });
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setWidth(0);
        setHeight(0);
        setLength(0);
        setUpperLimit(0);
        setLowerLimit(0);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  // function onlyUnique(value, index, self) {
  //     return self.findIndex(el => el.ply == value.ply) === index;
  // }

  const getPly = async () => {
    try {
      const { data: res } = await getAllPly();
      if (res) {
        let tempArr = [...res.data];
        console.log(tempArr);
        setPlyDisplayArr([
          ...res.data.filter(
            (el, i, arr) => arr.findIndex((elx) => elx.ply == el.ply) == i
          ),
        ]);
        setSpecVirginDisplayArr([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specVirgin == el.specVirgin) == i
          ),
        ]);
        setSpecSemiDisplayArr([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specSemi == el.specSemi) == i
          ),
        ]);
        console.log([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specSemi == el.specSemi) == i
          ),
        ]);
        setPlyArr(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getCategory = async () => {
    try {
      const { data: res } = await getAllCategory();
      if (res) {
        setCategoryArr(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getGlobalConstraint = async () => {
    try {
      const { data: res } = await getAllglobalConstraints();
      if (res) {
        if (res.data && res.data.length > 0) {
          setGlobalConstraintsObj(res.data[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetAllProducts = async () => {
    try {
      let { data: res } = await getAllProducts();
      console.log("this is res>>", res);
      if (res) {
        setProductArr(res.data);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  const checkSelectedCategory = () => {
    if (selectedProduct) {
      let selectedProductIndex = productArr.findIndex(
        (item) => item.categoryId === selectedProduct
      );
      if (selectedProductIndex != -1) {
        if (
          productArr[selectedProductIndex].categoryName
            .toLowerCase()
            .includes("box")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getBanner = async () => {
    let { data: res } = await getAllBanner();
    console.log(res, "getgertert");
    console.log(res?.data, "g2453533etgertert");
    if (res?.data && res?.data.length > 0) {
      setBanner(res.data);
    }
  };

  useEffect(() => {
    handleGetAllProducts();
    getPly();
    getGlobalConstraint();
    getCategory();
    getBanner();
  }, []);

  var settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
  };

  const getQuoteStickers = () => {
    let L = parseInt(stickerlength);
    let W = parseInt(stickerwidth);
    console.log(L, W);
    let paper_size = (L / 0.95) * (W / 0.95);
    console.log(paper_size, "paper_size");

    let costOfStickers = 29;
    if (
      globalConstraintsObj?.costOfStickers &&
      !isNaN(globalConstraintsObj?.costOfStickers)
    ) {
      costOfStickers = globalConstraintsObj?.costOfStickers;
    }
    let paper_cost = (paper_size / 1000000) * costOfStickers * 1000;
    console.log(paper_cost, "paper_cost");

    let conversionCost = paper_cost * 0.4;
    let finalCost = paper_cost + conversionCost;
    console.log(finalCost);
    let percentage = (5 / 100) * finalCost;
    // console.log(percentage, "percentage")
    setLowerLimit(roundTill2points(finalCost));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };

  const checkWeatherBoxOrNot = (name) => {
    // console.log(name)
    // return true
    console.log(name?.toLowerCase().includes("box"), "name");
    return name?.toLowerCase().includes("box");
    // return name.toLowerCase().includes("box")
  };

  const checkCalculateOrNot = () => {
    if (selectedProduct) {
      let selectedProductIndex = productArr.findIndex(
        (item) => item.categoryId === selectedProduct
      );
      if (selectedProductIndex != -1) {
        if (
          productArr[selectedProductIndex].categoryName
            .toLowerCase()
            .includes("box") ||
          productArr[selectedProductIndex].categoryName
            .toLowerCase()
            .includes("sticker")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };


  useEffect(() => {
    getQuoteStickers();
  }, [stickerlength, stickerwidth]);

  useEffect(() => {
    getQuote();
  }, [
    length,
    width,
    height,
    selectedPly,
    selectedSpecSemi,
    selectedSpecVirgin,
  ]);






  return (
    <div>
      <main className="customBackground">




      <div className="swiper_smaindlide">
                <Swiper
                  spaceBetween={5}
                  slidesPerView={6}
                  speed={4000}
                  className="location_areacolum"
                  loop
                  breakpoints={{
                    0: { slidesPerView: 1, }, 992: { slidesPerView: 1, },
                  }}
                  modules={[Navigation]}
                  navigation
                >
              {console.log(banner,"bannerbannerbanner")}
            {banner &&
              banner.length > 0 &&
              banner.map((el,index) => {

                return (
                  <SwiperSlide>
                  <div className="box_location">
                    <img src={`${url}/uploads/${el?.image}`} className="img-fluid" />
                  </div>
                </SwiperSlide>
                )


              })}
                </Swiper>
                </div>



<div class="hero">
  <video autoplay="autoplay" loop="loop" muted="muted" width="100%">
    <source src={banner_video} type="video/webm"/>
    <source src={banner_video} type="video/mp4"/>
  </video>
</div>

<section class="slider-text-section">
<div class="container">
<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 home-masthead-intro emerge" data-emergence="visible">
<h1 class="brandon gold">JSH PACKAGINGS</h1> 
<span class="luxury white">Our History</span>
<p class="white intro-text">J.S.H.Packagings, is one of the leading Manufacturers of Carton Boxes in India , we started this unit in October 1998 with 1 unit in Noida and within 2 Decades we have expanded to 6 units spread across India catering to PRESTIGOUS CLENTELE across India.</p> 
<a href="#" class="btn btn-primary">Read More</a></div>
</div>
</section>
        

              


        <div className="swiperslidermain">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                {/* <SimpleImageSlider
        width={'95vw'}
        height={700}
        images={images}
        showBullets={true}
        showNavs={true}
        className="slidernew img-fluid"
      />   */}
             
                {/* <Swiper
                  spaceBetween={15}
                  slidesPerView={1}
                  autoplay={true}
                  speed={1000}
                  delay={3}
                  loop
                  navigation
                  modules={[Navigation]}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    900: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                  }}
                >
                  <SwiperSlide>
                    <img src={slider1} className="img-fluid" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={slider2} className="img-fluid" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={slider3} className="img-fluid" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={slider4} className="img-fluid" />
                  </SwiperSlide>
                </Swiper> */}
              </div>
            </div>
          </div>
        </div>

        <div className="section section-fluid customBackground learts-pt-10 learts-pb-30">
          <div className="container">
            {/* <div className="section-title learts-mb-40 text-center">
                      <h2 className="title">Our Partners</h2>
                  </div> */}

            {/* brands  */}

            {/* <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            responsive={responsiveCrousel}
                            // ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                            autoPlaySpeed={5000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            // removeArrowOnDeviceType={["tablet", "mobile"]}
                            // deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-60-px"
                        >

                            <div className="brand-carousel col">
                                <div className="brand-item">
                                    <img src="assets/images/brands/1.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/2.png" alt="brands" />

                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/3.jpeg" alt="brands" />

                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/4.jpeg" alt="brands" />

                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/5.png" alt="brands" />

                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/6.png" alt="brands" />

                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/7.png" alt="brands" />

                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/8.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/9.jpeg" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/10.jpeg" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/11.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/12.webp" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/13.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/14.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/15.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/16.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/18.png" alt="brands" />
                                </div>
                            </div>


                            <div className="col brand-carousel">
                                <div className="brand-item">
                                    <img src="assets/images/brands/19.jpeg" alt="brands" />
                                </div>
                            </div>
                        </Carousel> */}
            {/* <Slider {...settings}>


                        </Slider> */}
          </div>
        </div>

        {/* commented section   */}

        {/* <div className="section learts-pt-60 learts-pb-60 bg-white">
                    <div className="container">
                        <div className="row learts-mb-n30">
                            <div className="col-lg-3 col-sm-6 col-12 learts-mb-30 p-0">
                                <div className="icon-box5">
                                    <div className="icon"><i className="fas fa-truck" /></div>
                                    <div className="content">
                                        <h4 className="title">1 Pack minimum order</h4>
                                        <p>+ Bulk discounts available</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 learts-mb-30">
                                <div className="icon-box5">
                                    <div className="icon"><i className="fas fa-headset" /></div>
                                    <div className="content">
                                        <h4 className="title">UK Made</h4>
                                        <p>Ready to go</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 learts-mb-30">
                                <div className="icon-box5">
                                    <div className="icon"><i className="fas fa-redo-alt" /></div>
                                    <div className="content">
                                        <h4 className="title">Eco-friendly</h4>
                                        <p>Recyclable packaging</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-12 learts-mb-30">
                                <div className="icon-box5">
                                    <div className="icon"><i className="fas fa-gift" /></div>
                                    <div className="content">
                                        <h4 className="title">Award winning</h4>
                                        <p>Expert design team</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="section section-fluid  pt-3 pb-0 ">
          <div className="container">
            <div className="row learts-mb-n30 gx-2">
              <div className="col-lg-3 col-6 learts-mb-30 marginb30">
                <div className="">
                  <div className="sale-banner7">
                    <div className="inner">
                      <div className="image">
                        <img
                          src="assets/images/packages/CorrugatedBox_02.jpg"
                          className="h-100"
                          alt="Sale Banner Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6 learts-mb-30 marginb30">
                <div className="sale-banner7">
                  <div className="inner">
                    <div className="image">
                      <img
                        src="assets/images/packages/CorrugatedBox_03.jpg"
                        className="h-100"
                        alt="Sale Banner Image"
                      />
                    </div>
                    <div className="content">
                      <h3 className="title">Corrugated Box</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-6 learts-mb-30 marginb30">
                <div className="sale-banner7">
                  <div className="inner">
                    <div className="image">
                      <img
                        src="assets/images/packages/Sticker_01.jpg"
                        className="h-100"
                        alt="Sale Banner Image"
                      />
                    </div>
                    <div className="content">
                      <h3 className="title">Stickers</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-12 learts-mb-30">
                                <div className="row learts-mb-n30">
                                    <div className="col-sm-6 col-12 learts-mb-30">
                                        <div className="sale-banner7">
                                            <div className="inner">
                                                <div className="image"><img src="assets/images/packages/Canaste_01.jpg" alt="Sale Banner Image" /></div>
                                                <div className="content">
                                                    <h3 className="title">Cannister</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 learts-mb-30">
                                        <div className="sale-banner7">
                                            <div className="inner">
                                                <div className="image"><img src="assets/images/packages/PackingFoam_01.jpg" alt="Sale Banner Image" /></div>
                                                <div className="content">
                                                    <h3 className="title">Packing Foam</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 learts-mb-30">
                                        <div className="sale-banner7">
                                            <div className="inner">
                                                <div className="image"><img src="assets/images/packages/Sticker_01.jpg" alt="Sale Banner Image" /></div>
                                                <div className="content">
                                                    <h3 className="title">Stickers</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 learts-mb-30">
                                        <div className="sale-banner7">
                                            <div className="inner">
                                                <div className="image"><img src="assets/images/packages/Tap_01.jpg" alt="Sale Banner Image" /></div>
                                                <div className="content">
                                                    <h3 className="title">Tapes</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <div className="col-lg-3 col-6 learts-mb-30 marginb30">
                <div className="sale-banner7">
                  <div className="inner">
                    <div className="image">
                      <img
                        src="assets/images/packages/corrugated-boxesKraft_06.jpg"
                        className="h-100"
                        alt="Sale Banner Image"
                      />
                    </div>
                    {/* <div className="content">
                                            <h3 className="title"></h3>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-fluid section-padding about-sec">
          <div className="container">
            <div className="row align-items-center g-0">
              <div className="col-md-6 col-12 text-center learts-mb-30 about-us3-img">
                <img src="assets/images/about/8.jpg" alt />
              </div>
              <div className="col-md-6 col-12 align-self-center">
                <div className="about-us3 ps-md-5 ms-md-5">
                  <span className="sub-title mb-1">Live out your life.</span>
                  <h2 className="title m-0">
                    The happiness of crafting artworks
                  </h2>
                  <div className="desc">
                    <p>
                      J.S.H.Packagings, is one of the leading Manufacturers of
                      Carton Boxes in India , we started this unit in October
                      1998 with 1 unit in Noida and within 2 Decades we have
                      expanded to 6 units spread across India catering to
                      PRESTIGOUS CLENTELE across India Our multiple units is
                      measuring about 2,45,000 Sq. Ft and is equipped with
                      state-of-the-art plant & machinery.
                    </p>
                  </div>
                  <a href="/about" className="link">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section-padding revartsectino section-fluid pb-0">
          <div className="container-fluid">
            <div className="section-title2 text-center">
              <h2 className="title ">
                Fully recyclable, eco friendly packaging
              </h2>
              <p>
                Browse a wide range of distinctive pieces of arts you could
                never find elsewhere.
              </p>
            </div>
            <div className="row g-0">
              <div className="col-lg-6 col-12">
                <div className="sale-banner9">
                  <div className="inner">
                    <div className="content">
                      <h3 className="title">Eco Friendly</h3>
                      <h4 className="mb-5">Hamper Packaging</h4>
                      {/* <span className="cuppon">EVERYTHING WITH CODE: <span className="code">NEW 30</span></span>
                                  <span className="offer">30% OFF</span> */}
                      <a href="/contact" className="btn btn-dark btn-hover-primary">
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="sale-banner9-image">
                  <img
                    src="assets/images/packages/banner-clean-1.jpg"
                    alt
                    style={{ objectPosition: "right" }}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0        mobile-revarsi">
              <div className="col-lg-6 col-12">
                <div className="sale-banner9-image">
                  <img src="assets/images/packages/CustomPrinting.jpg" alt />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="sale-banner9">
                  <div className="inner">
                    <div className="content">
                      <h3 className="title">Custom Printed</h3>
                      <h4 className="mb-5">Packaging</h4>
                      {/* <span className="cuppon">EVERYTHING WITH CODE: <span className="code">NEW 30</span></span>
                                  <span className="offer">30% OFF</span> */}
                      <a href="/contact" className="btn btn-dark btn-hover-primary">
                        Enquire now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<div class="feat bg-gray why_choose">
    <div class="container">
      <div class="row">
        <div class="section-head col-sm-12">
        <div class="section-title2 text-center"><h2 class="title ">Why Choose Us?</h2>
        <p>Packaging solutions that protect, present, and promote your products - J.S.H. Packagings, your
reliable partner.</p>
        </div>
          
          
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_one"><i class="fa fa-inr"></i></span>
            <h6>Environmental friendly</h6>
            <p>Sustainable production, recycled materials, and earth-friendly solutions - our commitment to the
environment.</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_two"><i class="fa fa-shipping-fast"></i></span>
            <h6>Timely delivery</h6>
            <p>On-time delivery guaranteed: trust us to meet your packaging needs without delays.</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_three"><i class="fa fa-hourglass-half"></i></span>
            <h6>Competitive Pricing</h6>
            <p>Affordable rates, unbeatable value, and cost-effective packaging solutions.</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="item"> <span class="icon feature_box_col_four"><i class="fa fa-recycle"></i></span>
            <h6>Customer Service</h6>
            <p>Committed to Your Satisfaction, We Provide Customized Solutions for Your Packaging Needs.</p>
          </div>
        </div>
        
      </div>
    </div>
  </div>








        <div className="section section-fluid video-banner-section" data-bg-image="assets/images/packages/Kraft-Website-Banner-2.png"
            style={{
              backgroundImage:
                "url('assets/images/packages/Kraft-Website-Banner-2.png')",
            }}>
        <div class="container">
          <div
            className="video-banner">
            <div className="content col-md-7 col-lg-5">
              <div className="section-title2 mb-4 text-start">
                <span>MIX &amp; MATCH</span>
                <h2 className="title">Your Gift Packaging One-Stop-Shop</h2>
                <p>
                  Everything you need for perfect gifts - boxes, postal outers,
                  tissue, shred &amp; ribbon. All with immediate despatch.
                </p>
              </div>
              <div className="d-flex align-items-center gap-4">
                {/* <a href="https://www.youtube.com/watch?v=1jSsy7DtYgc" className="video-popup">
                              <img src="assets/images/icons/button-play.webp" alt="" />
                          </a> */}
                <a href="#" className="btn btn-dark btn-hover-primary">
                  Shop Gift Packaging
                </a>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="section section-padding pb-0 sectionteam">
          <div className="container">
            <div className="section-title2 text-center">
              <h2 className="title">Plant Details</h2>
              <p>
                Browse a wide range of distinctive pieces of arts you could
                never find elsewhere.
              </p>
            </div>
            <div className="row row-cols-md-3 row-cols-sm-2 row-cols-1 learts-mb-n30">
              <div className="col learts-mb-30">
                <div className="team">
                  <div className="image">
                    <img
                      src="assets/images/packages/AutomaticCorrugationPlan.jpg"
                      style={{ height: 280 }}
                      alt
                    />
                  </div>
                  <div className="content">
                    <h6 className="name">Automatic Corrugated Plant</h6>
                  </div>
                </div>
              </div>
              <div className="col learts-mb-30">
                <div className="team">
                  <div className="image">
                    <img
                      src="assets/images/packages/automaticprintingmachine.jpg"
                      style={{ height: 280 }}
                      alt
                    />
                  </div>
                  <div className="content">
                    <h6 className="name">Automatic Corrogated Printer</h6>
                  </div>
                </div>
              </div>
              <div className="col learts-mb-30">
                <div className="team">
                  <div className="image">
                    <img
                      src="assets/images/team/team-3.jpg"
                      style={{ height: 280 }}
                      alt
                    />
                  </div>
                  <div className="content">
                    <h6 className="name">Automatic Cutter</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-fluid section-padding calltoaction-section">
          <div
            className="video-banner home-banner"
            data-bg-image="assets/images/packages/web-main-banner.jpg"
            style={{
              backgroundImage: "url('assets/images/packages/Getintouch.jpg')",
            }}
          >
            <div className="content col-md-7 col-xl-6 mx-auto">
              <div className="section-title2 mb-4 text-center">
                <span>How can we help you</span>
                {
                  <h2 className="title">
                    Just call at <a href="tel:8586926709">+91 - 8586926709</a>{" "}
                    for any query
                  </h2>
                }
                <p className="text-dark">
                  Everything you need for perfect gifts - boxes, postal outers,
                  tissue, shred &amp; ribbon. All with immediate despatch.
                </p>
                <a href="./contact" className="btn btn-primary">
                  Get In Touch
                </a>
              </div>
            </div>
          </div>
        </div>


<div className="slider-testimonial">

    <div class="section-title2 text-center">
      <h2 class="title ">Google Reviews</h2>
      <p>Google reviews provide useful information and help you to choose from the best. </p>
    </div>

    <div className="slide item">
      <div className="testimonial">
        <div className="testi-item">
         <blockquote>Best institute for commerce students, those students who had a great fear of accounts and economics , this place is like heaven for them.”<br/>
<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></blockquote>
         <p className="author">Anurag Chauhan</p>
        </div>
        <div className="testi-item">
         <blockquote>JSH Packagings, suppliers of packagings materials to india's best automobile companies. no. 1 company in delhi ncr.
<br/>
<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
</blockquote>
         <p className="author">Pushpendra Sharma</p>
        </div>
        <div className="testi-item">
         <blockquote>Huge manufacturing capacity of all types of cartons and packagings material in all over india.<br/>
<i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></blockquote>
         <p className="author">Pramod Namdev</p>
        </div>
      </div>

      <div className="slider-img">
        <img src={google_review_img_1} alt="Author Image"/>
      </div>

    </div>



  </div>





      </main>
    </div>
  );
}
