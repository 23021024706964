import React, { useEffect, useState } from 'react'
import { getByUserId } from '../services/Order'
import { url } from '../services/url';
import downloadImage from "../assets/images/downloading-file.png"
export default function OrderHistory() {
    const [orderHistoryArr, setOrderHistoryArr] = useState([]);
    let handleGetUserOrder = async () => {
        try {
            let { data: res } = await getByUserId()
            console.log(res.data)
            if (res.data) {
                setOrderHistoryArr(res.data)
            }
        } catch (err) {
            if (err.response.data.message) {
                console.error(err.response.data.message)
                alert(err.response.data.message)
            }
            else {
                console.error(err)
                alert(err)

            }
        }
    }


    useEffect(() => {
        handleGetUserOrder()
    }, [])



    return (
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-12">

                    <table class="table" style={{minHeight:'52vh'}}>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Invoice Date</th>
                                <th scope="col">Invoice no</th>
                                <th scope="col">Name</th>
                                <th scope="col">Invoice Value</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Messages</th>
                                <th scope="col">Download Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderHistoryArr.map((el, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{new Date(el.invoiceDate).toDateString()}</td>
                                            <td>{el.invoiceNumber}</td>
                                            <td>{el.userObj.name}</td>
                                            <td>INR {el.invoiceValue}</td>
                                            <td>{el.paymentStatus}</td>
                                            <td style={{ color: el.status == "ORDERED" ? "grey" : el.status == "DISPATCHED" ? "black" : el.status == "shipped" ? "blue" : el.status == "DELIVERED" && "green" }}>{el.status}</td>
                                            <td>
                                                <div>
                                                    {
                                                        el.messageArr.map((el, index) => {
                                                            if (el?.message) {
                                                                return (
                                                                    <div> {el?.message} {index == el?.messageArr?.length - 1 ? "" : ","} </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><a style={{ textDecorationLine: "underline", textDecorationColor: "#FF4C00" }} target="_blank" href={`${url}/uploads/${el.fileUrl}`} download><img src={downloadImage} style={{ height: 20, width: 20 }} /></a></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
}
