import React, { useContext, useEffect, useState } from 'react'
import { decodedToken, login, setToken } from '../services/loginServices';
import Swal from "sweetalert2";
import { authContext, roleContext } from '../App';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useContext(roleContext);
    const [isAuthorized, setIsAuthorized] = useContext(authContext);
    let navigate = useNavigate()


    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            if (email == "") {
                alert("Please Enter Email");
            } else if (password == "") {
                alert("Please Enter Password");
            } else {
                let obj = {
                    email,
                    password,
                };
                let { data: res } = await login(obj);
                console.log(res);
                if (res) {
                    Swal.fire({
                        title: "Success",
                        type: "success",
                        icon: "success",
                        text: `${res.message}`,
                    });
                    console.log(res.token);
                    setToken(res.token);
                    localStorage.setItem("loggedInUser", JSON.stringify(res.userObj));
                    let decodedTokenValue = await decodedToken();
                    console.log(decodedTokenValue);
                    if (decodedTokenValue) {
                        setRole(decodedTokenValue.role);
                        setIsAuthorized(true);
                    }
                    navigate('/')

                }
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                console.error(err.response.data.message);
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: `${err.response.data.message}`,
                });
            } else {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: `${err}`,
                });
            }
        }
    };

    useEffect(() => {

    }, [])


    return (
        <>
            <main className="">
                {/* Page Title/Header Start */}
                <div className="section section-fluid">
                    <div className="container-fluid">
                        <div className="page-title-section" data-bg-image="assets/images/packages/10.jpeg">
                            <div className="row">
                                <div className="col">
                                    <div className="page-title">
                                        <h1 className="title">Login</h1>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active">Login</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Title/Header End */}
                {/* Login & Register Section Start */}
                <div className="section section-padding">
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-lg-6">
                                <div className="user-login-register bg-light">
                                    <div className="login-register-title">
                                        <h2 className="title">Login</h2>
                                        <p className="desc">Great to have you back!</p>
                                    </div>
                                    <div className="login-register-form">
                                        <form action="#">
                                            <div className="row learts-mb-n50">
                                                <div className="col-12 learts-mb-50">
                                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Username or email address" />
                                                </div>
                                                <div className="col-12 learts-mb-50">
                                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                                </div>
                                                <div className="col-12 text-center learts-mb-50">
                                                    <button onClick={handleLogin} className="btn btn-dark btn-outline-hover-dark">login</button>
                                                </div>
                                                <div className="col-12 learts-mb-50">
                                                    <div className="row learts-mb-n20">
                                                        <div className="col-12 learts-mb-20">
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input" id="rememberMe" />
                                                                <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 learts-mb-20">
                                                            <a href="lost-password" className="fw-400">Lost your password?</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 mx-auto d-flex align-items-center'>
                                <img src="./assets/images/packages/registerImage.png" alt />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Login & Register Section End */}
                {/* <div className="shadow-img">
                    <img src="./assets/images/packages/shadow.jpg" alt />
                </div> */}
            </main>
        </>
    )
}
