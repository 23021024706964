import { axiosApiInstance } from '../App';
import { url } from './url'
const axios = require('axios');


const serverUrl = `${url}/globalConstraints`

export const globalConstraintsAdd = async (formData) => {
    return axios.post(`${serverUrl}/`, formData)
}

export const getAllglobalConstraints = async () => {
    return axios.get(`${serverUrl}/`)
}

export const getglobalConstraintsById = async (id) => {
    return axios.get(`${serverUrl}/getById/${id}`)
}

export const deleteglobalConstraints = async (id) => {
    return axios.delete(`${serverUrl}/deleteById/${id}`)
}

export const editglobalConstraints = async (id, obj) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, obj)
}
