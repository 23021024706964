import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { addEnquiry } from "../services/enquiry";
import { getAllProducts } from "../services/product";
import { url } from "../services/url";

import { useSearchParams } from "react-router-dom";
import { getAllPly, plySpecArr } from "../services/Ply";
import { getAllCategory } from "../services/category";
import { addEcomEnquiry } from "../services/EcomEnquiry";
import { getAllglobalConstraints } from "../services/globalConstraints";
export default function Products() {
  const [productArr, setProductArr] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(0);
  const [stickerwidth, setStickerWidth] = useState(0);
  const [stickerlength, setStickerLength] = useState(0);
  const [globalConstraintsObj, setGlobalConstraintsObj] = useState({});

  const [productObj, setProductObj] = useState({});
  const [loggedInUser, setLoggedInUser] = useState({});
  const [upperLimit, setUpperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [imageArr, setImageArr] = useState([]);

  const [search, setSearch] = useSearchParams();

  const [plyArr, setPlyArr] = useState([]);
  const [selectedPly, setSelectedPly] = useState("");
  const [selectedSpecVirgin, setSelectedSpecVirgin] = useState("");
  const [selectedSpecSemi, setSelectedSpecSemi] = useState("");
  const [selectedNoOfSpec, setSelectedNoOfSpec] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productForEnquiry, setProductForEnquiry] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [selectedNoOfSpecSemi, setSelectedNoOfSpecSemi] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [paramsCategoryId, setParamsCategoryId] = useState("");
  const plySpecsArr = plySpecArr;

  const [plyDisplayArr, setPlyDisplayArr] = useState([]);
  const [specVirginDisplayArr, setSpecVirginDisplayArr] = useState([]);
  const [specSemiDisplayArr, setSpecSemiDisplayArr] = useState([]);
  const handleGetAllProducts = async () => {
    try {
      let { data: res } = await getAllProducts();
      console.log("this is res>>", res);
      if (res) {
        res.data = [
          ...res.data.map((el) => ({
            ...el,
            imagesArr: el.imagesArr.reverse(),
          })),
        ];
        let tempArr = [
          "Packing Accessories",
          "Foam",
          "Tapes",
          "Barcode Ribbon",
        ];
        if (search.get("categoryName")) {
          let tempParamCategoryName = decodeURIComponent(
            search.get("categoryName")
          );
          if (tempParamCategoryName.includes("Foam")) {
            setProductArr([
              ...res.data.filter(
                (el) =>
                  el.name.includes("Foam") ||
                  el.categoryName ==
                    decodeURIComponent(search.get("categoryName"))
              ),
            ]);
          } else if (tempParamCategoryName.includes("Tapes")) {
            setProductArr([
              ...res.data.filter(
                (el) =>
                  el.name.includes("Tapes") ||
                  el.categoryName ==
                    decodeURIComponent(search.get("categoryName"))
              ),
            ]);
          } else if (tempParamCategoryName.includes("Barcode Ribbon")) {
            setProductArr([
              ...res.data.filter(
                (el) =>
                  el.name.includes("Barcode Ribbon") ||
                  el.categoryName ==
                    decodeURIComponent(search.get("categoryName"))
              ),
            ]);
          } else {
            setProductArr([
              ...res.data.filter(
                (el) =>
                  tempArr.includes(el.name) ||
                  el.categoryName ==
                    decodeURIComponent(search.get("categoryName"))
              ),
            ]);
          }
          setParamsCategoryId(search.get("categoryId"));
          setSelectedProduct(search.get("categoryId"));
        } else {
          if (search.get("categoryId")) {
            setProductArr([
              ...res.data.filter(
                (el) => el.categoryId == search.get("categoryId")
              ),
            ]);
            setParamsCategoryId(search.get("categoryId"));
            setSelectedProduct(search.get("categoryId"));
          } else {
            setProductArr([...res.data]);
          }
        }
        setProductForEnquiry([...res.data]);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let obj = {
        // userId: loggedInUser._id,
        // productId: productObj._id,
        name,
        email,
        phone,
        message,
        length: checkSelectedCategory()
          ? parseInt(length)
          : parseInt(stickerlength),
        breadth: checkSelectedCategory()
          ? parseInt(width)
          : parseInt(stickerwidth),
        height: checkSelectedCategory() ? parseInt(height) : 0,
        ply: selectedPly ? selectedPly : 3,
        specVirgin: selectedSpecVirgin ? selectedSpecVirgin : 180,
        semiSpec: selectedSpecSemi ? selectedSpecSemi : 120,
        NoOfSpec: selectedNoOfSpec ? selectedNoOfSpec : 2,
        numberOfSpecSemi: selectedNoOfSpecSemi ? selectedNoOfSpecSemi : 1,
        company: companyName,

        isBox: checkSelectedCategory(),
      };
      let { data: res } = await addEnquiry(obj);
      console.log("this is res>>", res);
      if (res) {
        Swal.fire({
          title: "Success",
          type: "success",
          icon: "success",
          text: `${res.message}`,
        });
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setWidth(0);
        setHeight(0);
        setLength(0);
        setUpperLimit(0);
        setLowerLimit(0);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };
  const handleEcomEnquiry = async (e) => {
    e.preventDefault();

    try {
      let obj = {
        productName: "",
        range: 0,
        name,
        email,
        phone,
        message,
        company: companyName,
      };
      console.log(obj, "sent obj");
      let { data: res } = await addEcomEnquiry(obj);
      console.log("this is res>>", res);
      if (res) {
        Swal.fire({
          title: "Success",
          type: "success",
          icon: "success",
          text: `${res.message}`,
        });
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setWidth(0);
        setHeight(0);
        setLength(0);
        setUpperLimit(0);
        setLowerLimit(0);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };
  const checkSelectedCategory = () => {
    console.log(selectedProduct, "selected product");
    if (selectedProduct) {
      let selectedProductIndex = productArr.findIndex(
        (item) => item.categoryId === selectedProduct
      );
      if (selectedProductIndex != -1) {
        if (
          productArr[selectedProductIndex].categoryName
            .toLowerCase()
            .includes("box")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkCalculateOrNot = () => {
    if (selectedProduct) {
      let selectedProductIndex = productArr.findIndex(
        (item) => item.categoryId === selectedProduct
      );
      if (selectedProductIndex != -1) {
        if (
          productArr[selectedProductIndex].categoryName
            .toLowerCase()
            .includes("box") ||
          productArr[selectedProductIndex].categoryName
            .toLowerCase()
            .includes("sticker")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const roundTill2points = (value) => {
    return Math.round(value * 10000) / 10000;
  };
  const getPly = async () => {
    try {
      const { data: res } = await getAllPly();
      if (res) {
        console.log(res.data, "ply data");
        setPlyDisplayArr([
          ...res.data.filter(
            (el, i, arr) => arr.findIndex((elx) => elx.ply == el.ply) == i
          ),
        ]);
        setSpecVirginDisplayArr([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specVirgin == el.specVirgin) == i
          ),
        ]);
        setSpecSemiDisplayArr([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specSemi == el.specSemi) == i
          ),
        ]);
        console.log([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specSemi == el.specSemi) == i
          ),
        ]);
        setPlyArr(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getCategory = async () => {
    try {
      const { data: res } = await getAllCategory();
      if (res) {
        setCategoryArr(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getQuote = () => {
    let L = parseInt(length);
    let W = parseInt(width);
    let H = parseInt(height);
    console.log(L, W, H);
    let A = roundTill2points((W + H + 15) / 25.4);
    let A_round = Math.ceil(A);
    console.log(A, A_round, "A,A_round");
    let B = roundTill2points((2 * L + 2 * W + 80) / 25.4);
    let B_round = Math.ceil(B);
    console.log(B, B_round, "B,B_round");

    let ply = 3;
    if (selectedPly) {
      ply = parseInt(selectedPly);
    }

    let specVirgin = 180;
    if (selectedSpecVirgin) {
      specVirgin = parseInt(selectedSpecVirgin);
    }

    let NoOfSpec = 2;
    if (selectedNoOfSpec) {
      NoOfSpec = parseInt(selectedNoOfSpec);
    }

    let plySpecCheck = plySpecsArr.find((el) => el.ply == ply);
    if (plySpecCheck) {
      NoOfSpec = plySpecCheck.specVirgin;
    }
    let global_cost_virgin = 48;
    if (
      globalConstraintsObj.costVirgin &&
      !isNaN(globalConstraintsObj.costVirgin)
    ) {
      global_cost_virgin = parseInt(globalConstraintsObj.costVirgin);
    }

    let cost_virgin = roundTill2points(
      ((A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97) *
        global_cost_virgin
    );
    console.log(cost_virgin, "cost_virgin");
    let semiSpec = 120;
    if (selectedSpecSemi) {
      semiSpec = parseInt(selectedSpecSemi);
    }
    let numberOfSpecSemi = 1;
    console.log(selectedNoOfSpecSemi, "selectedNoOfSpecSemi");
    if (selectedNoOfSpecSemi) {
      numberOfSpecSemi = parseInt(selectedNoOfSpecSemi);
    }
    if (plySpecCheck) {
      numberOfSpecSemi = plySpecCheck.specSemi;
    }
    // semiSpec = semiSpec * numberOfSpecSemi
    let semiSpecUsed = semiSpec * 1.5;

    let global_cost_semi = 41;
    if (
      globalConstraintsObj.costSemi &&
      !isNaN(globalConstraintsObj.costSemi)
    ) {
      global_cost_semi = parseInt(globalConstraintsObj.costSemi);
    }
    let cost_semi = roundTill2points(
      ((A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97) *
        global_cost_semi
    );
    console.log(cost_semi, "cost_semi");
    let grossWeight = roundTill2points(
      (A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97 +
        (A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97
    );
    console.log(grossWeight, "grossWeight");
    let totalCostPaper = roundTill2points(cost_semi + cost_virgin);
    console.log(totalCostPaper, "totalCostPaper");
    let conversionCost = roundTill2points(grossWeight * 9.5);
    console.log(conversionCost, "conversionCost");
    let totalCost = totalCostPaper + conversionCost;
    console.log(totalCost, "totalCost");
    let finalCost = totalCost;
    finalCost = Math.ceil(finalCost);

    console.log(finalCost, "finalCost");
    let percentage = (5 / 100) * finalCost;
    console.log(percentage, "percentage");
    setLowerLimit(roundTill2points(finalCost));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };

  const getGlobalConstraint = async () => {
    try {
      const { data: res } = await getAllglobalConstraints();
      if (res) {
        if (res.data && res.data.length > 0) {
          setGlobalConstraintsObj(res.data[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGlobalConstraint();
    handleGetAllProducts();
    getCategory();
    getPly();
  }, []);

  const getQuoteStickers = () => {
    let L = parseInt(stickerlength);
    let W = parseInt(stickerwidth);
    console.log(L, W);
    let paper_size = (L / 0.95) * (W / 0.95);
    console.log(paper_size, "paper_size");

    let paper_cost = (paper_size / 1000000) * 29 * 1000;
    console.log(paper_cost, "paper_cost");

    let conversionCost = paper_cost * 0.4;
    let finalCost = paper_cost + conversionCost;
    console.log(finalCost);
    let percentage = (10 / 100) * finalCost;
    // console.log(percentage, "percentage")
    setLowerLimit(roundTill2points(finalCost - percentage));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };

  const checkWeatherBoxOrNot = (name) => {
    // console.log(name)
    // return true
    console.log(name?.toLowerCase().includes("box"), "name");
    return name?.toLowerCase().includes("box");
    // return name.toLowerCase().includes("box")
  };
  useEffect(() => {
    getQuoteStickers();
  }, [stickerlength, stickerwidth]);

  useEffect(() => {
    getQuote();
  }, [
    length,
    width,
    height,
    selectedPly,
    selectedSpecSemi,
    selectedSpecVirgin,
  ]);

  const CategoryItem = ({ data, index }) => {
    return (
      <>
        <div key={index} className="col">
          <div className="product">
            <div className="product-thumb">
              {data && data?.imageArr && data?.imageArr.length > 0}
              <a href={`product-details/${data._id}`} className="image">
                <img
                  src={`${url}/uploads/${data?.imagesArr[0]?.imageUrl}`}
                  alt="Product"
                />
                {data?.imagesArr[1] && (
                  <img
                    src={`${url}/uploads/${data?.imagesArr[1]?.imageUrl}`}
                    class="image-hover "
                    alt="Product"
                  />
                )}
              </a>
            </div>
            <div className="product-info">
              <h6 className="title">
                <a href={`product-details/${data._id}`}>{data?.name}</a>
              </h6>
              <h6 className="title">₹ {data?.price}</h6>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <main className="">
        {/* Page Title/Header Start */}
        <div className="section section-fluid">
          <div className="container-fluid">
            <div
              className="page-title-section"
              data-bg-image="assets/images/packages/10.jpeg"
            >
              <div className="row">
                <div className="col">
                  <div className="page-title">
                    <h1 className="title">Customised Products</h1>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Customised Products
                      </li> 
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Title/Header End */}
        {/* Product Section Start */}
        <div>
          <div className="row">
            <div className="col-12 col-xxl-5 col-lg-6 my-4 pt-5">
              <div className="row mx-sm-5 mx-0 align-items-center enquiryDiv">
                <div className="col-12 align-self-center text-center">
                  <h2 className="title">Enquire Now</h2>
                  <div className="contact-form">
                    <form
                      action="assets/php/contact-mail"
                      id="contact-form"
                      method="post"
                    >
                      <div className="row learts-mb-n30">
                        <div className="col-12 learts-mb-30">
                          <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Your Name *"
                            name="name"
                          />
                        </div>
                        <div className="col-12 learts-mb-30">
                          <input
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            type="text"
                            placeholder="Company Name*"
                            name="name"
                          />
                        </div>

                        <div className="col-md-6 col-12 learts-mb-30">
                          <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            placeholder="Email *"
                            name="email"
                          />
                        </div>
                        <div className="col-md-6 col-12 learts-mb-30">
                          <input
                            value={phone}
                            maxLength={10}
                            onChange={(e) => setPhone(e.target.value)}
                            type="tel"
                            placeholder="Phone No. *"
                            name="phone number"
                          />
                        </div>
                        <div className="col-md-12 col-12 learts-mb-10">
                          <div className="slidecontainer row d-flex align-items-baseline justify-content mt-4">
                            {paramsCategoryId == "" && (
                              <div
                                className="col-12"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="text-start text-nowrap"
                                  htmlFor=""
                                >
                                  Category
                                </label>
                                <select
                                  placeholder="Select Product"
                                  className="form-control ml-4"
                                  onChange={(e) =>
                                    setSelectedProduct(e.target.value)
                                  }
                                >
                                  <option>Select Category</option>
                                  {categoryArr.map((el) => {
                                    return (
                                      <option key={el._id} value={el._id}>
                                        {el.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            )}
                            {checkCalculateOrNot() && (
                              <>
                                {checkSelectedCategory() ? (
                                  <>
                                    <div className="col-6 ">
                                      <div className="slidecontainer d-flex align-items-baseline">
                                        <label
                                          className="text-start mb-0 text-nowrap"
                                          htmlFor=""
                                        >
                                          Length(mm)
                                        </label>
                                      </div>
                                      <input
                                        type="tel"
                                        minLength="1"
                                        maxLength="5"
                                        value={length}
                                        onChange={(e) => {
                                          setLength(e.target.value);
                                        }}
                                        className="slider homeEnqInput"
                                      />
                                    </div>
                                    <div className="col-6">
                                      <div className="slidecontainer d-flex align-items-baseline">
                                        <label
                                          className="text-start mb-0 text-nowrap"
                                          htmlFor=""
                                        >
                                          Width:(mm)
                                        </label>
                                      </div>
                                      <input
                                        type="tel"
                                        minLength="1"
                                        maxLength="5"
                                        value={width}
                                        onChange={(e) => {
                                          setWidth(e.target.value);
                                        }}
                                        className="slider homeEnqInput"
                                      />
                                    </div>

                                    <div className="col-12 mb-4">
                                      <div className="slidecontainer d-flex align-items-baseline mt-4">
                                        <label
                                          className="text-start mb-0 text-nowrap"
                                          htmlFor=""
                                        >
                                          Height(mm)
                                        </label>
                                      </div>
                                      <input
                                        type="tel"
                                        minLength="1"
                                        maxLength="5"
                                        value={height}
                                        onChange={(e) => {
                                          setHeight(e.target.value);
                                        }}
                                        className="slider homeEnqInput"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-6 ">
                                      <div className="slidecontainer d-flex align-items-baseline">
                                        <label
                                          className="text-start mb-0 text-nowrap"
                                          htmlFor=""
                                        >
                                          Length(mm)
                                        </label>
                                      </div>
                                      <input
                                        type="tel"
                                        minLength="1"
                                        maxLength="5"
                                        value={stickerlength}
                                        onChange={(e) => {
                                          setStickerLength(e.target.value);
                                        }}
                                        className="slider homeEnqInput"
                                      />
                                    </div>
                                    <div className="col-6">
                                      <div className="slidecontainer d-flex align-items-baseline">
                                        <label
                                          className="text-start mb-0 text-nowrap"
                                          htmlFor=""
                                        >
                                          Width:(mm)
                                        </label>
                                      </div>
                                      <input
                                        type="tel"
                                        minLength="1"
                                        maxLength="5"
                                        value={stickerwidth}
                                        onChange={(e) => {
                                          setStickerWidth(e.target.value);
                                        }}
                                        className="slider homeEnqInput"
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                            {checkCalculateOrNot() && checkSelectedCategory() && (
                              <>
                                <div
                                  className="col-6 mb-4"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    className="text-start  text-nowrap"
                                    htmlFor=""
                                  >
                                    Ply
                                  </label>

                                  <select
                                    placeholder="Select Product"
                                    className="form-control"
                                    onChange={(e) =>
                                      setSelectedPly(e.target.value)
                                    }
                                  >
                                    <option>Select Ply</option>
                                    {plyDisplayArr.map((el) => {
                                      return (
                                        <option key={el._id} value={el.ply}>
                                          {el.ply}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div
                                  className="col-6"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    className="text-start  text-nowrap"
                                    htmlFor=""
                                  >
                                    Spec Virgin
                                  </label>

                                  <select
                                    placeholder="Select Product"
                                    className="form-control"
                                    onChange={(e) =>
                                      setSelectedSpecVirgin(e.target.value)
                                    }
                                  >
                                    <option>Spec Virgin</option>
                                    {specVirginDisplayArr.map((el) => {
                                      return (
                                        <option
                                          key={el._id}
                                          value={el.specVirgin}
                                        >
                                          {el.specVirgin}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div
                                  className="col-12"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label
                                    className="text-start text-nowrap"
                                    htmlFor=""
                                  >
                                    Spec Semi
                                  </label>

                                  <select
                                    placeholder="Select Product"
                                    className="form-control"
                                    onChange={(e) =>
                                      setSelectedSpecSemi(e.target.value)
                                    }
                                  >
                                    <option>Semi Spec </option>
                                    {specSemiDisplayArr.map((el) => {
                                      return (
                                        <option
                                          key={el._id}
                                          value={el.specSemi}
                                        >
                                          {el.specSemi}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </>
                            )}
                          </div>

                          {/* <div className="slidecontainer row d-flex align-items-baseline justify-content mt-4">
                                                        {checkCalculateOrNot() && checkSelectedCategory() &&
                                                            <>

                                                                <div className='col-6' style={{ display: "flex", flexDirection: "column" }}>

                                                                    <label className='text-start  mt-2 text-nowrap' htmlFor="">No Of Specs</label>

                                                                    <select placeholder='Select Product' className='form-control ' onChange={(e) => setSelectedNoOfSpec(e.target.value)} >
                                                                        <option>No Of specs </option>
                                                                        {plyArr.map(el => {
                                                                            return (
                                                                                <option key={el._id} value={el.NoOfSpec}>{el.NoOfSpec}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className='col-6' style={{ display: "flex", flexDirection: "column" }}>

                                                                    <label className='text-start  mt-2 text-nowrap' htmlFor="">No Of Specs Semi</label>

                                                                    <select placeholder='Select Product' className='form-control ' onChange={(e) => setSelectedNoOfSpecSemi(e.target.value)} >
                                                                        <option>No Of specs Semi </option>
                                                                        {plyArr.map(el => {
                                                                            return (
                                                                                <option key={el._id} value={el.NoOfSpecSemi}>{el.NoOfSpecSemi}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </>
                                                        }
                                                    </div> */}
                        </div>
                        {checkCalculateOrNot() && (
                          <div className="col-12 text-start total-amount">
                            <label>Upper Limit and Lower Limit</label>
                            <p className="m-0">
                              <b>₹ {lowerLimit}</b>{" "}
                              <span style={{ fontWeight: "normal" }}>
                                &nbsp;To&nbsp;
                              </span>
                              <b>₹ {upperLimit}</b>
                            </p>
                            {selectedProduct === "62454c25762130914b37faed" && (
                              <p>For 1000 stickers</p>
                            )}
                          </div>
                        )}

                        <div className="col-12 ">
                          <textarea
                            className="homeEnqInput"
                            style={{ height: "100px" }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            name="message"
                            placeholder="Message"
                            spellCheck="false"
                            defaultValue={""}
                          />
                        </div>
                        {checkCalculateOrNot() ? (
                          <div className="col-12 mt-3">
                            <button
                              onClick={handleSubmit}
                              className="btn btn-dark btn-outline-hover-dark"
                              style={{
                                backgroundColor: "#F8796C",
                                borderColor: "#F8796C",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        ) : (
                          <div className="col-12 mt-3">
                            <button
                              onClick={handleEcomEnquiry}
                              className="btn btn-dark btn-outline-hover-dark"
                              style={{
                                backgroundColor: "#F8796C",
                                borderColor: "#F8796C",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                    <p className="form-messege" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xxl-7 col-lg-6">
              <div className="section section-padding  product-sec">
                <div className="container">
                  <div className="row justify-content-between">
                    {/* Section Title Start */}
                    <div className="col-12">
                      <div className="section-title2 text-center mb-0">
                        <h2 className="title">Our Products</h2>
                      </div>
                    </div>
                    {/* Section Title End */}
                    {/* Section Title End */}
                  </div>
                  <div className="products row row-cols-md-3 row-cols-sm-2 row-cols-1">
                    {productArr?.map((cat, index) => {
                      return <CategoryItem key={index} data={cat} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product Section End */}
      </main>
    </>
  );
}
