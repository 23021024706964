import React, { useState, useEffect } from 'react'
import { forgotPassword, verifyOtpAndUpdate } from '../services/loginServices';



export default function ForgotPass() {

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [stepper, setStepper] = useState(0);
    const handleOtpSend = async () => {
        try {
            const { data: res } = await forgotPassword(email);
            if (res) {
                setStepper(1)
                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.data.message) {
                alert(error.response.data.message)
            }
            else {
                alert(error.message)
            }
        }
    }
    const handlePasswordUpdate=async()=>{
        try {
            let obj={
                email,
                password,
                otp
            }
            const { data: res } = await verifyOtpAndUpdate(obj);
            if (res) {
                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            if (error.response.data.message) {
                alert(error.response.data.message)
            }
            else {
                alert(error.message)
            }
        }
    }

    return (
        <>
            <main className="bg-white">
                {/* Page Title/Header Start */}
                <div className="section section-fluid bg-white">
                    <div className="container-fluid">
                        <div className="page-title-section" data-bg-image="assets/images/packages/10.jpeg">
                            <div className="row">
                                <div className="col">
                                    <div className="page-title">
                                        <h1 className="title">Reset Password</h1>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active">Reset Password</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Title/Header End */}
                {/* Lost Password Section Start */}
                <div className="section section-padding bg-white">
                    <div className="container">
                        <div className="lost-password">
                            <p>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</p>
                            <form>
                                <div className="row learts-mb-n30">
                                    {stepper == 0 ?
                                        <>
                                            <div className="col-12 learts-mb-30">
                                                <label htmlFor="userName">Username or email</label>
                                                <input id="userName" type="text" onChange={(e) => setEmail(e.target.value)} value={email} />
                                            </div>
                                            <div className="col-12 text-center learts-mb-30">
                                                <button className="btn btn-dark btn-outline-hover-dark" type='button' onClick={() => handleOtpSend()}>reset password</button>
                                            </div>
                                        </>

                                        :
                                        <>
                                            <div className="col-12 learts-mb-30">
                                                <label htmlFor="userName">Otp</label>
                                                <input id="userName" type="text" onChange={(e) => setOtp(e.target.value)} value={otp} />
                                            </div>
                                            <div className="col-12 learts-mb-30">
                                                <label htmlFor="userName">Password</label>
                                                <input id="userName" type="text" onChange={(e) => setPassword(e.target.value)} value={password} />
                                            </div>
                                            <div className="col-12 text-center learts-mb-30">
                                                <button className="btn btn-dark btn-outline-hover-dark" type='button' onClick={() => handlePasswordUpdate()}>reset password</button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Lost Password Section End */}
            </main>
        </>
    )
}
