import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { decodedToken, register } from '../services/loginServices';

export default function Register() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [companyName, setCompanyName] = useState("");
    let navigate = useNavigate()


    const handleRegister = async (e) => {
        e.preventDefault()
        try {
            if (name == "") {
                alert("Please Enter Name");
            } else if (email == "") {
                alert("Please Enter Email");
            }
            else if (phone == "") {
                alert("Please Enter Phone");
            }
            else if (password == "") {
                alert("Please Enter Password");
            }
            else if (confirmPassword !== password) {
                alert("Passoword do not match");
            }
            else if (companyName == "") {
                alert("Please Enter Company Name");
            }
            else {
                let obj = {
                    name,
                    email,
                    phone,
                    password,
                    companyName
                };
                let { data: res } = await register(obj);
                console.log('this is res>>', res);
                if (res) {
                    Swal.fire({
                        title: "Success",
                        type: "success",
                        icon: "success",
                        text: `${res.message}`,
                    });
                    navigate('/login')
                }
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                console.error(err.response.data.message);
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: `${err.response.data.message}`,
                });
            } else {
                console.error(err);
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: `${err}`,
                });
            }
        }
    };

    return (
        <>
            <main className="">
                {/* Page Title/Header Start */}
                <div className="section section-fluid">
                    <div className="container-fluid">
                        <div className="page-title-section" data-bg-image="assets/images/packages/10.jpeg">
                            <div className="row">
                                <div className="col">
                                    <div className="page-title">
                                        <h1 className="title">Register</h1>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active">Register</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Title/Header End */}
                {/* Login & Register Section Start */}
                <div className="section section-padding register-form">
                    <div className="container">
                        <div className="row g-0">
                            <div className="col-lg-6">
                                <div className="user-login-register bg-light">
                                    <div className="login-register-title">
                                        <h2 className="title">Register</h2>
                                        <p className="desc">If you don’t have an account, register now!</p>
                                    </div>
                                    <div className="login-register-form">
                                        <form action="#">
                                            <div className="row learts-mb-n50">
                                                <div className="col-12 learts-mb-20">
                                                    <label htmlFor="register-name">Name</label>
                                                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Your Name *" name="name" id="register-name" />
                                                </div>
                                                <div className="col-12 learts-mb-20">
                                                    <label htmlFor="register-name">Company Name</label>
                                                    <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="Company Name *" name="name" id="register-name" />
                                                </div>
                                                <div className="col-12 learts-mb-20">
                                                    <label htmlFor="register-name">Phone</label>
                                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} type="number" placeholder="Your Number *" name="name" id="register-name" />
                                                </div>
                                                <div className="col-12 learts-mb-20">
                                                    <label htmlFor="registerEmail">Email address <abbr className="required">*</abbr></label>
                                                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="registerEmail" placeholder="Your Email Address" />
                                                </div>
                                                <div className="col-12 learts-mb-20">
                                                    <label htmlFor="register-password">Password</label>
                                                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" id="register-password" />
                                                </div>
                                                <div className="col-12 learts-mb-20">
                                                    <label htmlFor="register-password2">Comfirm Password</label>
                                                    <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Comfirm Password" id="register-password2" />
                                                </div>
                                                <div className="col-12 learts-mb-50">
                                                    <p>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy</p>
                                                </div>
                                                <div className="col-12 text-center learts-mb-50">
                                                    <button onClick={handleRegister} className="btn btn-primary  btn-md btn-pad me-3">Register</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 mx-auto d-flex align-items-center'>
                                <img src="./assets/images/packages/registerImage.png" style={{height:'45%'}} alt />
                            </div>

                        </div>
                    </div>
                </div>
                {/* Login & Register Section End */}
                {/* <div className="shadow-img">
                    <img src="./assets/images/packages/shadow.jpg" alt />
                </div> */}
            </main>
        </>
    )
}
