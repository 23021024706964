import React from "react";

export default function About() {
  return (
    <>
      <div>
        {/* Page Title/Header Start */}
        <div className="section section-fluid">
          <div className="container-fluid">
            <div className="page-title-section" data-bg-image="assets/images/packages/10.jpeg">
              <div className="row">
                <div className="col">
                  <div className="page-title">
                    <h1 className="title">About Us</h1>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active">About Us</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Title/Header End */}
        <div className="section section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 align-self-center">
                <div className="about-us4">
                  <span className="sub-title m-0">JSH PACKAGINGS</span>
                  <h2 className="title">Our History</h2>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <p>
                        J.S.H.Packagings, is one of the leading Manufacturers of Carton Boxes in India , we started this unit in October 1998 with 1 unit in Noida and within 2 Decades we have expanded to 6 units spread across India catering
                        to PRESTIGOUS CLENTELE across India Our multiple units is measuring about 2,45,000 Sq. Ft and is equipped with state-of-the-art plant & machinery
                      </p>
                      <p>Our manufacturing units are situated at following locations: </p>
                      <ul>
                        <li>NOIDA (UTTAR PRADESH) </li>
                        <li>BAWAL (HARYANA) </li>
                        <li>Rewari (HARYANA) </li>
                        <li>HARIDWAR (UTTRAKHAND) </li>
                        <li>PILLIAPIKUM (TAMIL NADU) </li>
                        <li>PUNE (MAHARASHTRA) </li>
                      </ul>
                      <p>
                        Keeping in mind the requirements (quality, time & cost) of the new generation of discerning customer, we have installed state of the art fully automated imported 5-ply plant. The plant has the capacity to churn out
                        9000 tons per month. Its current installed capacity is to manufacture 2.5 Lac Boxes per day which is to be ramped up around double by next fiscal year.{" "}
                      </p>
                    </div>
                    <div className="col-12 col-lg-6 learts-mb-30 customBoxShadow">
                      <div className="sale-banner4">
                        <div className="inner">
                          <img src="assets/images/banner/Map2.jpg" alt="Sale Banner Image" className="img-fluid" />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-6 learts-mb-30  mr-2 customBoxShadow">
                      <div className="sale-banner4">
                        <div className="inner">
                          <img src="assets/images/banner/CorrugatedPallats.jpg" alt="Sale Banner Image" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-3 my-3">
                      <p>
                        Apart from corrugated boxes, it is also into all types of Wooden boxes, pallets & offers Complete Palletized packing Solution to many prestigious clients across the country which have been used for the export
                        purpose.
                      </p>
                      <p className="mt-2">
                        With the creative and intelligent use of World class technologies & Global practices, we manufacture Packaging in our endeavor to help our customer offer high quality products at competitive price. We have set up a
                        dedicated “Research & Design” team who study, analyze & develop various options to select from, with all testing facilities & equipment.
                      </p>
                      <p>To accommodate needs & urgency we develop packaging on fast track thus let customers to fulfill their commitments. </p>
                    </div>
                    <div className="col-12 col-lg-6 my-3">
                      <p>
                        Apart from box manufacturing, in our continuous efforts to provide our esteemed customers a “Complete packaging solution”, we have started providing services for line packing of parts & finished products under expert
                        supervision & efficient process.
                      </p>
                      <p>
                        We, thus, offer a seamless operations of complete packaging requirement including design, development, manufacturing, supply & packing with high quality at low cost. We, thus, offer a seamless operations of complete
                        packaging requirement including design, development, manufacturing, supply & packing with high quality at low cost.{" "}
                      </p>
                      <p>We maintain optimum level of both imported and indigenous stock of Kraft paper for our customers.</p>
                      <p>We are an ISO 9001:2015 Certified company; we are also completely equipped to test the product at various stages of its manufacture. </p>
                      <p>We believe in both the letter and the spirit of the dictum that Customer is Supreme and to serve the customer to the best of our ability with the support of all the stake holders in our business. </p>
                    </div>
                    <div className="col-12 col-lg-6 learts-mb-30 my-3 ml-2 customBoxShadow">
                      <div className="sale-banner4">
                        <div className="inner">
                          <img src="assets/images/packages/CBD-Boxes-Wholesale-03.jpg" alt="Sale Banner Image" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="section section-padding pt-0 about-gallery">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 learts-mb-30">
                                <div className="sale-banner4">
                                    <div className="inner">
                                        <img src="assets/images/packages/hero.webp" alt="Sale Banner Image" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 learts-mb-30">
                                <div className="row learts-mb-n30">
                                    <div className="col-12 learts-mb-30">
                                        <div className="sale-banner5">
                                            <a href="#" className="inner">
                                                <img src="assets/images/packages/banner2.jpg" alt="Sale Banner Image" className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 learts-mb-30">
                                        <div className="sale-banner6">
                                            <div className="inner">
                                                <img src="assets/images/packages/nursery.jpg" alt="Sale Banner Image" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 learts-mb-30">
                                <div className="row learts-mb-n30">
                                    <div className="col-12 learts-mb-30">
                                        <div className="sale-banner5">
                                            <a href="#" className="inner">
                                                <img src="assets/images/packages/CBD-Box-Packaging-01.jpg" alt="Sale Banner Image" className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 learts-mb-30">
                                        <div className="sale-banner6">
                                            <div className="inner">
                                                <img src="assets/images/packages/13-Custom-gift-card-box-01.jpg" alt="Sale Banner Image" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
}
