import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Swiper, SwiperSlide } from "swiper/react";
import { addEnquiry } from "../services/enquiry";
import { getAllProducts, getproductById } from "../services/product";
import { url } from "../services/url";
import ImageGallery from "react-image-gallery";
import { Badge } from "react-bootstrap";
import { addEcomEnquiry } from "../services/EcomEnquiry";
import { getAllglobalConstraints } from "../services/globalConstraints";
import { getAllPly, plySpecArr } from "../services/Ply";

export default function ProductDetails() {
  const baseUrl = url;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [width, setWidth] = useState(1);
  const [length, setLength] = useState(1);
  const [height, setHeight] = useState(1);
  const [productObj, setProductObj] = useState({});
  const [loggedInUser, setLoggedInUser] = useState({});
  const [finalCost, setFinalCost] = useState("");
  const [upperLimit, setUpperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);
  const params = useParams();
  const [imageArr, setImageArr] = useState([]);
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [show, setShow] = useState(false);

  const [EcomEnquiryEnabled, setEcomEnquiryEnabled] = useState(true);
  const [globalConstraintsObj, setGlobalConstraintsObj] = useState({});

  const [plyArr, setPlyArr] = useState([]);
  const [selectedPly, setSelectedPly] = useState("");
  const [selectedSpecVirgin, setSelectedSpecVirgin] = useState("");
  const [selectedSpecSemi, setSelectedSpecSemi] = useState("");
  const [selectedNoOfSpec, setSelectedNoOfSpec] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [stickerwidth, setStickerWidth] = useState(0);
  const [stickerlength, setStickerLength] = useState(0);
  const [selectedNoOfSpecSemi, setSelectedNoOfSpecSemi] = useState("");

  const [companyName, setCompanyName] = useState("");

  const plySpecsArr = plySpecArr;

  const [plyDisplayArr, setPlyDisplayArr] = useState([]);
  const [specVirginDisplayArr, setSpecVirginDisplayArr] = useState([]);
  const [specSemiDisplayArr, setSpecSemiDisplayArr] = useState([]);
  const navigate = useNavigate();
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  const handleGetProductById = async () => {
    try {
      let { data: res } = await getproductById(params.id);
      console.log("this is res>>", res);
      if (res) {
        // Swal.fire({
        //     title: "Success",
        //     type: "success",
        //     icon: "success",
        //     text: `${res.message}`,
        // });
        setProductObj(res.data);
        let tempImageArr = res.data.imagesArr.reverse().map((el, index) => {
          let obj = {
            original: `${url}/uploads/${el.imageUrl}`,
            thumbnail: `${url}/uploads/${el.imageUrl}`,
          };
          return obj;
        });
        setImageArr(tempImageArr);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  const getGlobalConstraint = async () => {
    try {
      const { data: res } = await getAllglobalConstraints();
      if (res) {
        if (res.data && res.data.length > 0) {
          setGlobalConstraintsObj(res.data[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let user = localStorage.getItem("loggedInUser");
      if (!user) {
        alert("Please Login to continue");
      } else {
        if (EcomEnquiryEnabled) {
          let obj = {
            userId: loggedInUser._id,
            productId: productObj._id,
            productName: productObj.name,
            range: productObj.price,
            name,
            email,
            phone,
            message,
            company: companyName,
          };
          console.log(obj, "sent obj");
          let { data: res } = await addEcomEnquiry(obj);
          console.log("this is res>>", res);
          if (res) {
            Swal.fire({
              title: "Success",
              type: "success",
              icon: "success",
              text: `${res.message}`,
            });
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
            setWidth(0);
            setHeight(0);
            setLength(0);
            setUpperLimit(0);
            setLowerLimit(0);
          }
        } else {
          console.log(productObj);
          let obj = {
            userId: loggedInUser._id,
            productId: productObj._id,
            name,
            email,
            phone,
            message,
            length: checkSelectedCategory()
              ? parseInt(length)
              : parseInt(stickerlength),
            breadth: checkSelectedCategory()
              ? parseInt(width)
              : parseInt(stickerwidth),
            height: checkSelectedCategory() ? parseInt(height) : 0,
            isBox: checkWeatherBoxOrNot(productObj?.title),
            companyName,
          };
          console.log(obj, "sent obj");
          let { data: res } = await addEnquiry(obj);
          console.log("this is res>>", res);
          if (res) {
            Swal.fire({
              title: "Success",
              type: "success",
              icon: "success",
              text: `${res.message}`,
            });
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
            setWidth(0);
            setHeight(0);
            setLength(0);
            setUpperLimit(0);
            setLowerLimit(0);
          }
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };
  const roundTill2points = (value) => {
    return Math.round(value * 10000) / 10000;
  };
  const getQuote = () => {
    let L = parseInt(length);
    let W = parseInt(width);
    let H = parseInt(height);
    console.log(L, W, H);
    let A = roundTill2points((W + H + 15) / 25.4);
    let A_round = Math.ceil(A);
    console.log(A, A_round, "A,A_round");
    let B = roundTill2points((2 * L + 2 * W + 80) / 25.4);
    let B_round = Math.ceil(B);
    console.log(B, B_round, "B,B_round");

    let ply = 3;
    if (selectedPly) {
      ply = parseInt(selectedPly);
    }

    let specVirgin = 180;
    if (selectedSpecVirgin) {
      specVirgin = parseInt(selectedSpecVirgin);
    }

    let NoOfSpec = 2;
    if (selectedNoOfSpec) {
      NoOfSpec = parseInt(selectedNoOfSpec);
    }

    let plySpecCheck = plySpecsArr.find((el) => el.ply == ply);
    if (plySpecCheck) {
      NoOfSpec = plySpecCheck.specVirgin;
    }
    let global_cost_virgin = 48;
    if (
      globalConstraintsObj.costVirgin &&
      !isNaN(globalConstraintsObj.costVirgin)
    ) {
      global_cost_virgin = parseInt(globalConstraintsObj.costVirgin);
    }

    let cost_virgin = roundTill2points(
      ((A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97) *
        global_cost_virgin
    );
    console.log(cost_virgin, "cost_virgin");
    let semiSpec = 120;
    if (selectedSpecSemi) {
      semiSpec = parseInt(selectedSpecSemi);
    }
    let numberOfSpecSemi = 1;
    console.log(selectedNoOfSpecSemi, "selectedNoOfSpecSemi");
    if (selectedNoOfSpecSemi) {
      numberOfSpecSemi = parseInt(selectedNoOfSpecSemi);
    }
    if (plySpecCheck) {
      numberOfSpecSemi = plySpecCheck.specSemi;
    }
    // semiSpec = semiSpec * numberOfSpecSemi
    let semiSpecUsed = semiSpec * 1.5;

    let global_cost_semi = 41;
    if (
      globalConstraintsObj.costSemi &&
      !isNaN(globalConstraintsObj.costSemi)
    ) {
      global_cost_semi = parseInt(globalConstraintsObj.costSemi);
    }
    let cost_semi = roundTill2points(
      ((A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97) *
        global_cost_semi
    );
    console.log(cost_semi, "cost_semi");
    let grossWeight = roundTill2points(
      (A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97 +
        (A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97
    );
    console.log(grossWeight, "grossWeight");
    let totalCostPaper = roundTill2points(cost_semi + cost_virgin);
    console.log(totalCostPaper, "totalCostPaper");
    let conversionCost = roundTill2points(grossWeight * 9.5);
    console.log(conversionCost, "conversionCost");
    let totalCost = totalCostPaper + conversionCost;
    console.log(totalCost, "totalCost");
    let finalCost = totalCost;
    finalCost = Math.ceil(finalCost);

    console.log(finalCost, "finalCost");
    let percentage = (5 / 100) * finalCost;
    console.log(percentage, "percentage");
    setLowerLimit(roundTill2points(finalCost));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };
  const handleEcomEnquiry = async (e) => {
    e.preventDefault();

    try {
      let obj = {
        productName: "",
        range: 0,
        name,
        email,
        phone,
        message,
      };
      console.log(obj, "sent obj");
      let { data: res } = await addEcomEnquiry(obj);
      console.log("this is res>>", res);
      if (res) {
        Swal.fire({
          title: "Success",
          type: "success",
          icon: "success",
          text: `${res.message}`,
        });
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setWidth(0);
        setHeight(0);
        setLength(0);
        setUpperLimit(0);
        setLowerLimit(0);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  const getPly = async () => {
    try {
      const { data: res } = await getAllPly();
      if (res) {
        setPlyDisplayArr([
          ...res.data.filter(
            (el, i, arr) => arr.findIndex((elx) => elx.ply == el.ply) == i
          ),
        ]);
        setSpecVirginDisplayArr([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specVirgin == el.specVirgin) == i
          ),
        ]);
        setSpecSemiDisplayArr([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specSemi == el.specSemi) == i
          ),
        ]);
        console.log([
          ...res.data.filter(
            (el, i, arr) =>
              arr.findIndex((elx) => elx.specSemi == el.specSemi) == i
          ),
        ]);

        console.log(res.data, "ply data");
        setPlyArr(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getQuoteStickers = () => {
    let L = parseInt(stickerlength);
    let W = parseInt(stickerwidth);
    console.log(L, W);
    let paper_size = (L / 0.95) * (W / 0.95);
    console.log(paper_size, "paper_size");

    let costOfStickers = 29;
    if (
      globalConstraintsObj?.costOfStickers &&
      !isNaN(globalConstraintsObj?.costOfStickers)
    ) {
      costOfStickers = globalConstraintsObj?.costOfStickers;
    }
    let paper_cost = (paper_size / 1000000) * costOfStickers * 1000;
    console.log(paper_cost, "paper_cost");

    let conversionCost = paper_cost * 0.4;
    let finalCost = paper_cost + conversionCost;
    console.log(finalCost);
    let percentage = (5 / 100) * finalCost;
    // console.log(percentage, "percentage")
    setLowerLimit(roundTill2points(finalCost));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };

  const checkWeatherBoxOrNot = (name) => {
    console.log(name?.toLowerCase().includes("box"), "name");
    return name?.toLowerCase().includes("box");
    // return name.toLowerCase().includes("box")
  };
  const checkCalculateOrNot = () => {
    if (productObj?.categoryName) {
      if (
        productObj?.categoryName.toLowerCase().includes("box") ||
        productObj.categoryName.toLowerCase().includes("sticker")
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const checkSelectedCategory = () => {
    if (productObj?.categoryName) {
      if (productObj?.categoryName.toLowerCase().includes("box")) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    getQuoteStickers();
  }, [stickerlength, stickerwidth]);

  useEffect(() => {
    getQuote();
  }, [
    length,
    width,
    height,
    selectedPly,
    selectedSpecSemi,
    selectedSpecVirgin,
  ]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("loggedInUser"));
    setLoggedInUser(user);
    handleGetProductById();
    getPly();
    getGlobalConstraint();
  }, []);

  return (
    <>
      <main className="">
        {/* Page Title/Header Start */}
        <div className="border-top section">
          <div className="container">
            <div className="page-title-section2">
              <div className="row">
                <div className="col">
                  <div className="page-title">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/products">Our Products</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {productObj?.name}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Title/Header End */}
        {/* Single Products Section Start */}
        <div className="section section-padding border-bottom pt-3">
          <div className="container">
            <div className="row learts-mb-n40">
              {/* Product Images Start */}
              <div className="col-lg-6 col-12 learts-mb-40">
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={imageArr}
                />

                {/* <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    style={{ height: "700px", width: "100%" }}
                                >
                                    {productObj.imagesArr?.map(img => {
                                        return (
                                            <>
                                                <SwiperSlide>
                                                    <div className="product-zoom" data-image="./assets/images/produts/1.webp">
                                                        <img src={`${url}/uploads/${img.imageUrl}`} alt="img" />
                                                    </div>
                                                </SwiperSlide>
                                            </>
                                        )
                                    })}
                                </Swiper> */}
                {/* <div className="product-images">
                                    <div className="product-gallery-slider">
                                        <div className="product-zoom" data-image="./assets/images/produts/1.webp">
                                            <img src="./assets/images/produts/1.webp" alt />
                                        </div>
                                        <div className="product-zoom" data-image="./assets/images/produts/2.gif">
                                            <img src="./assets/images/produts/2.gif" alt />
                                        </div>
                                        <div className="product-zoom" data-image="./assets/images/produts/3.jpg">
                                            <img src="./assets/images/produts/3.jpg" alt />
                                        </div>
                                        <div className="product-zoom" data-image="assets/images/produts/4.jpg">
                                            <img src="assets/images/produts/4.jpg" alt />
                                        </div>
                                        <div className="product-zoom" data-image="assets/images/produts/5.jpg">
                                            <img src="assets/images/produts/5.jpg" alt />
                                        </div>
                                        <div className="product-zoom" data-image="assets/images/produts/6.jpg">
                                            <img src="assets/images/produts/6.jpg" alt />
                                        </div>
                                    </div>
                                    <div className="product-thumb-slider">
                                        <div className="item">
                                            <img src="./assets/images/produts/1.webp" alt />
                                        </div>
                                        <div className="item">
                                            <img src="./assets/images/produts/2.gif" alt />
                                        </div>
                                        <div className="item">
                                            <img src="./assets/images/produts/3.jpg" alt />
                                        </div>
                                        <div className="item">
                                            <img src="assets/images/produts/4.jpg" alt />
                                        </div>
                                        <div className="item">
                                            <img src="assets/images/produts/5.jpg" alt />
                                        </div>
                                        <div className="item">
                                            <img src="assets/images/produts/6.jpg" alt />
                                        </div>
                                    </div>
                                </div> */}
              </div>
              {/* Product Images End */}
              {/* Product Summery Start */}
              <div className="col-lg-6 col-12 learts-mb-40">
                <div className="product-summery">
                  <h3 className="product-title">{productObj?.title}</h3>
                  <div className="product-description">
                    <p>{productObj?.description}</p>
                  </div>
                  {/* <div className="product-description">
                                        <p>Ply : {productObj?.ply}</p>
                                    </div> */}
                  <div className="product-description">
                    <p>Price : ₹ {productObj?.price}</p>
                  </div>
                  <div className="product-description">
                    {productObj?.isInStock ? (
                      <Badge bg="success">
                        <p style={{ color: "white" }}>In stock</p>
                      </Badge>
                    ) : (
                      <Badge bg="danger">
                        <p style={{ color: "white" }}>Out of stock</p>
                      </Badge>
                    )}
                  </div>
                  <div className="product-buttons">
                    {checkCalculateOrNot() ? (
                      <a
                        href="#quickViewModal"
                        onClick={() => setEcomEnquiryEnabled(false)}
                        data-bs-toggle="modal"
                        className="mt-3 btn btn-dark btn-outline-hover-dark"
                      >
                        Enquire{" "}
                      </a>
                    ) : (
                      <a
                        href="#quickViewModal1"
                        onClick={() => setEcomEnquiryEnabled(true)}
                        data-bs-toggle="modal"
                        className="mt-3 btn btn-dark btn-outline-hover-dark"
                      >
                        {" "}
                        Enquire Now
                      </a>
                    )}
                  </div>
                  <div className="product-buttons"></div>
                </div>
              </div>
              {/* Product Summery End */}
            </div>
          </div>
        </div>
        {/* Single Products Section End */}
      </main>

      {/* {showEnquiryModal && ( */}
      <div
        className="quickViewModal modal fade"
        id="quickViewModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button className="close" data-bs-dismiss="modal">
              ×
            </button>
            <div className="row align-items-center">
              <div className="col-12 align-self-center text-center">
                <h2 className="title">Enquire Now</h2>
                <div className="contact-form">
                  <form
                    action="assets/php/contact-mail"
                    id="contact-form"
                    method="post"
                  >
                    <div className="row learts-mb-n30">
                      <div className="col-12 learts-mb-30">
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="Your Name *"
                          name="name"
                        />
                      </div>
                      <div className="col-12 learts-mb-30">
                        <input
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          type="text"
                          placeholder="Company Name*"
                          name="name"
                        />
                      </div>

                      <div className="col-md-6 col-12 learts-mb-30">
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder="Email *"
                          name="email"
                        />
                      </div>
                      <div className="col-md-6 col-12 learts-mb-30">
                        <input
                          value={phone}
                          maxLength={10}
                          onChange={(e) => setPhone(e.target.value)}
                          type="tel"
                          placeholder="Phone No. *"
                          name="phone number"
                        />
                      </div>
                      <div className="col-md-12 col-12 learts-mb-10">
                        <div className="slidecontainer row d-flex align-items-baseline justify-content mt-4">
                          {checkCalculateOrNot() && (
                            <>
                              {checkSelectedCategory() ? (
                                <>
                                  <div className="col-6 learts-mb-10">
                                    <div className="slidecontainer d-flex align-items-baseline">
                                      <label
                                        className="text-start mb-0 text-nowrap"
                                        htmlFor=""
                                      >
                                        Length(mm)
                                      </label>
                                    </div>
                                    <input
                                      type="tel"
                                      minLength="1"
                                      maxLength="5"
                                      value={length}
                                      onChange={(e) => {
                                        setLength(e.target.value);
                                      }}
                                      className="slider homeEnqInput"
                                    />
                                  </div>
                                  <div className="col-6">
                                    <div className="slidecontainer d-flex align-items-baseline">
                                      <label
                                        className="text-start mb-0 text-nowrap"
                                        htmlFor=""
                                      >
                                        Width:(mm)
                                      </label>
                                    </div>
                                    <input
                                      type="tel"
                                      minLength="1"
                                      maxLength="5"
                                      value={width}
                                      onChange={(e) => {
                                        setWidth(e.target.value);
                                      }}
                                      className="slider homeEnqInput"
                                    />
                                  </div>

                                  <div className="col-12">
                                    <div className="slidecontainer d-flex align-items-baseline">
                                      <label
                                        className="text-start mb-0 text-nowrap"
                                        htmlFor=""
                                      >
                                        Height(mm)
                                      </label>
                                    </div>
                                    <input
                                      type="tel"
                                      minLength="1"
                                      maxLength="5"
                                      value={height}
                                      onChange={(e) => {
                                        setHeight(e.target.value);
                                      }}
                                      className="slider homeEnqInput"
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-6 learts-mb-10">
                                    <div className="slidecontainer d-flex align-items-baseline">
                                      <label
                                        className="text-start mb-0 text-nowrap"
                                        htmlFor=""
                                      >
                                        Length(mm)
                                      </label>
                                    </div>
                                    <input
                                      type="tel"
                                      minLength="1"
                                      maxLength="5"
                                      value={stickerlength}
                                      onChange={(e) => {
                                        setStickerLength(e.target.value);
                                      }}
                                      className="slider homeEnqInput"
                                    />
                                  </div>
                                  <div className="col-6">
                                    <div className="slidecontainer d-flex align-items-baseline">
                                      <label
                                        className="text-start mb-0 text-nowrap"
                                        htmlFor=""
                                      >
                                        Width:(mm)
                                      </label>
                                    </div>
                                    <input
                                      type="tel"
                                      minLength="1"
                                      maxLength="5"
                                      value={stickerwidth}
                                      onChange={(e) => {
                                        setStickerWidth(e.target.value);
                                      }}
                                      className="slider homeEnqInput"
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {checkCalculateOrNot() && checkSelectedCategory() && (
                            <>
                              <div
                                className="col-6"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="text-start mt-2 text-nowrap"
                                  htmlFor=""
                                >
                                  Ply
                                </label>

                                <select
                                  placeholder="Select Product"
                                  className="form-control"
                                  onChange={(e) =>
                                    setSelectedPly(e.target.value)
                                  }
                                >
                                  <option>Select Ply</option>
                                  {plyDisplayArr.map((el) => {
                                    return (
                                      <option key={el._id} value={el.ply}>
                                        {el.ply}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div
                                className="col-6"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="text-start mt-2 text-nowrap"
                                  htmlFor=""
                                >
                                  Spec Virgin
                                </label>

                                <select
                                  placeholder="Select Product"
                                  className="form-control"
                                  onChange={(e) =>
                                    setSelectedSpecVirgin(e.target.value)
                                  }
                                >
                                  <option>Spec Virgin</option>
                                  {specVirginDisplayArr.map((el) => {
                                    return (
                                      <option
                                        key={el._id}
                                        value={el.specVirgin}
                                      >
                                        {el.specVirgin}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="slidecontainer row d-flex align-items-baseline justify-content mt-4">
                          {checkCalculateOrNot() && checkSelectedCategory() && (
                            <>
                              <div
                                className="col-12"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label
                                  className="text-start mt-2 text-nowrap"
                                  htmlFor=""
                                >
                                  Spec Semi
                                </label>

                                <select
                                  placeholder="Select Product"
                                  className="form-control"
                                  onChange={(e) =>
                                    setSelectedSpecSemi(e.target.value)
                                  }
                                >
                                  <option>Semi Spec </option>
                                  {specSemiDisplayArr.map((el) => {
                                    return (
                                      <option key={el._id} value={el.specSemi}>
                                        {el.specSemi}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {/* <div className='col-5' style={{ display: "flex", flexDirection: "column" }}>

                                                                <label className='text-start  mt-2 text-nowrap' htmlFor="">No Of Specs</label>

                                                                <select placeholder='Select Product' className='form-control ' onChange={(e) => setSelectedNoOfSpec(e.target.value)} >
                                                                    <option>No Of specs </option>
                                                                    {plyArr.map(el => {
                                                                        return (
                                                                            <option key={el._id} value={el.NoOfSpec}>{el.NoOfSpec}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className='col-5' style={{ display: "flex", flexDirection: "column" }}>

                                                                <label className='text-start  mt-2 text-nowrap' htmlFor="">No Of Specs Semi</label>

                                                                <select placeholder='Select Product' className='form-control ' onChange={(e) => setSelectedNoOfSpecSemi(e.target.value)} >
                                                                    <option>No Of specs Semi </option>
                                                                    {plyArr.map(el => {
                                                                        return (
                                                                            <option key={el._id} value={el.NoOfSpecSemi}>{el.NoOfSpecSemi}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div> */}
                            </>
                          )}
                        </div>
                      </div>

                      {checkCalculateOrNot() && (
                        <div className="col-12 text-start total-amount">
                          <label>Upper Limit and Lower Limit</label>
                          <p className="m-0">
                            <b>₹ {lowerLimit}</b>{" "}
                            <span style={{ fontWeight: "normal" }}>
                              &nbsp;To&nbsp;
                            </span>
                            <b>₹ {upperLimit}</b>
                          </p>
                          {/* {selectedProduct === "62454c25762130914b37faed" && ( */}
                          <p>For 1000 stickers</p>
                          {/* )} */}
                        </div>
                      )}
                      <div className="col-12 ">
                        <textarea
                          className="homeEnqInput"
                          style={{ height: "100px" }}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          name="message"
                          placeholder="Message"
                          spellCheck="false"
                          defaultValue={""}
                        />
                      </div>
                      {checkCalculateOrNot() ? (
                        <div className="col-12 mt-3">
                          <button
                            onClick={handleSubmit}
                            className="btn btn-dark btn-outline-hover-dark"
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        <div className="col-12 mt-3">
                          <button
                            onClick={handleEcomEnquiry}
                            className="btn btn-dark btn-outline-hover-dark"
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                  <p className="form-messege" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="quickViewModal modal fade"
        id="quickViewModal1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button className="close" data-bs-dismiss="modal">
              ×
            </button>
            <div className="row align-items-center">
              <div className="col-12 align-self-center text-center">
                <h2 className="title">Enquire Now</h2>
                <div className="contact-form">
                  <form
                    action="assets/php/contact-mail"
                    id="contact-form"
                    method="post"
                  >
                    <div className="row learts-mb-n30">
                      <div className="col-12 learts-mb-30">
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="Your Name *"
                          name="name"
                        />
                      </div>
                      <div className="col-12 learts-mb-30">
                        <input
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          type="text"
                          placeholder="Company Name*"
                          name="name"
                        />
                      </div>

                      <div className="col-md-6 col-12 learts-mb-30">
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          placeholder="Email *"
                          name="email"
                        />
                      </div>
                      <div className="col-md-6 col-12 learts-mb-30">
                        <input
                          value={phone}
                          maxLength={10}
                          onChange={(e) => setPhone(e.target.value)}
                          type="tel"
                          placeholder="Phone No. *"
                          name="phone number"
                        />
                      </div>

                      <div className="col-12 learts-mb-30">
                        <textarea
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          name="message"
                          placeholder="Message"
                          spellCheck="false"
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-12 learts-mb-30">
                        <button
                          onClick={handleSubmit}
                          className="btn btn-dark btn-outline-hover-dark"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <p className="form-messege" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}
