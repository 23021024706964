import { url } from "./url";
import axios from "axios";
import jwt_decode from "jwt-decode";

const serverUrl = `${url}/order`;

export const getByUserId = async () => {
    let loggedInUser = localStorage.getItem("loggedInUser")
    loggedInUser = JSON.parse(loggedInUser)
    return await axios.get(`${serverUrl}/getByUserId/${loggedInUser._id}`);
}