import { url } from "./url";
import axios from "axios";
import jwt_decode from "jwt-decode";

const serverUrl = `${url}/users`;

export const login = async (obj) => {
    return await axios.post(`${serverUrl}/login`, obj);
}
export const register = async (obj) => {
    return await axios.post(`${serverUrl}/register`, obj);
}

export const setToken = (token) => {
    localStorage.setItem("AUTH_TOKEN", token)
}
export const getToken = () => {
    let token = localStorage.getItem("AUTH_TOKEN")
    return token
}
export const removeToken = () => {
    localStorage.removeItem("AUTH_TOKEN")
}
export const decodedToken = async () => {
    let token = localStorage.getItem("AUTH_TOKEN")
    var decoded = jwt_decode(token);
    return decoded
}


export const forgotPassword=async(email)=>{
    return await axios.post(`${serverUrl}/forgotPassword`,{email})
}
export const verifyOtpAndUpdate=async(obj)=>{
    return await axios.post(`${serverUrl}/verifyOtpAndUpdate`,obj)
}