import { url } from "./url";
import axios from "axios";

const serverUrl = `${url}/product`;

export const getAllProducts = async () => {
    return await axios.get(`${serverUrl}/`);
}
export const getproductById = async (id) => {
    return axios.get(`${serverUrl}/getById/${id}`)
}