import { url } from "./url";
import axios from "axios";
import jwt_decode from "jwt-decode";

const serverUrl = `${url}/ply`;

export const getAllPly = async () => {
    return await axios.get(`${serverUrl}/`);
}


export const plySpecArr = [
    { ply: 3, specVirgin: 2, specSemi: 1 },
    { ply: 5, specVirgin: 3, specSemi: 2 },
    { ply: 7, specVirgin: 4, specSemi: 3 },
    { ply: 9, specVirgin: 5, specSemi: 4 },

]



