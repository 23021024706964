import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Screens/About";
import Process from "./Screens/Process";
import Contact from "./Screens/Contact";
import ProductDetails from "./Screens/ProductDetails";
import Products from "./Screens/Products";
import Register from "./Screens/Register";
import Login from "./Screens/Login";
import ForgotPass from "./Screens/ForgotPass";
import { createContext, useEffect, useState } from "react";
import { decodedToken } from "./services/loginServices.js";
import OrderHistory from "./Screens/OrderHistory";
import Lottie from "react-lottie";
import * as animationData from "./assets/jshLoader.json";
export const authContext = createContext();
export const roleContext = createContext();

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [role, setRole] = useState("");

  const checkLogin = async () => {
    let token = await decodedToken();
    if (token) {
      setRole(token.role);
      setIsAuthorized(true);
    } else {
      setRole("");
      setIsAuthorized(false);
    }
  };
  const [loading, setLoading] = useState(true);
  const [isStopped, setIsStopped] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    checkLogin();
  }, []);

  return (
    <div className="App">
      <roleContext.Provider value={[role, setRole]}>
        <authContext.Provider value={[isAuthorized, setIsAuthorized]}>
          <BrowserRouter>
            {loading ? (
              <Lottie
                options={defaultOptions}
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "0",
                  right:'0',
                  textAlign:'center',
                  

                }}
                height={100}
                width={150}
                isStopped={isStopped}
              />
            ) : (
              <>
                <Header />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/process" element={<Process />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/orders" element={<OrderHistory />} />
                  <Route path="/products" element={<Products />} />
                  <Route
                    path="/product-details/:id"
                    element={<ProductDetails />}
                  />
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/lost-password" element={<ForgotPass />} />
                </Routes>
                <Footer />
              </>
            )}
          </BrowserRouter>
        </authContext.Provider>
      </roleContext.Provider>
    </div>
  );
}

export default App;
