import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { authContext } from "../App";
import { getAllCategory } from "../services/category";
import { getAllProducts } from "../services/product";
import logo from "../assets/images/JSH-Logo-Gif.gif";
import { getAllglobalConstraints } from "../services/globalConstraints";
import { getAllPly, plySpecArr } from "../services/Ply";
import { addEcomEnquiry } from "../services/EcomEnquiry";
import { addEnquiry } from "../services/enquiry";
import Accordion from 'react-bootstrap/Accordion';

export default function Header() { 
  let navigate = useNavigate();
  const [productArr, setProductArr] = useState([]);

  const [categoryArr, setCategoryArr] = useState([]);
  const [otherCategoryArr, setOtherCategoryArr] = useState([]);
  const [isAuthorized, setIsAuthorized] = useContext(authContext);
  console.log("isAuthorized", isAuthorized);
  const handleScroll = () => {
    let header = document.getElementById("myHeaderId");
    let scrollup = document.getElementById("scrollUp");
    let chatBtn = document.getElementById("chatBtn");
    window.addEventListener("scroll", function () {
      var scroll = window.scrollY;
      if (scroll > 300) {
        // console.log("greater than");
        header.classList.add("on-scroll");
        header.classList.add("fixed-top");
        chatBtn.classList.add("d-block");
        scrollup.classList.add("d-block");
      } else if (scroll < 300) {
        header.classList.remove("on-scroll");
        header.classList.remove("fixed-top");
        chatBtn.classList.remove("d-block");
        scrollup.classList.remove("d-block");
      }
    });
  };
  const handleForm = () => {
    let chatForm = document.getElementById("chatForm");
    chatForm.classList.toggle("suggestion-formHeight");
  };
  const handleSignout = () => {
    localStorage.clear();
    setIsAuthorized(false);
    navigate("/login");
  };

  const handleGetAllProducts = async () => {
    try {
      let { data: res } = await getAllProducts();
      console.log("this is res>>", res);
      if (res) {
        // Swal.fire({
        //     title: "Success",
        //     type: "success",
        //     icon: "success",
        //     text: `${res.message}`,
        // });
        setProductArr(res.data);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  const getCategory = async () => {
    try {
      const { data: res } = await getAllCategory();
      if (res) {
        let tempArr = ["Packing Accessories", "Foam", "Tapes", "Barcode Ribbons"];
        setCategoryArr(res.data.filter((item) => !tempArr.includes(item.name)));
        setOtherCategoryArr(res.data.filter((item) => tempArr.includes(item.name)));
        // setCategoryArr(res.data)
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleScroll();
    handleGetAllProducts();
    getCategory();
  }, []);

  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [height, setHeight] = useState(0);
  const [stickerwidth, setStickerWidth] = useState(0);
  const [stickerlength, setStickerLength] = useState(0);
  const [upperLimit, setUpperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [imageArr, setImageArr] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [plyArr, setPlyArr] = useState([]);
  const [selectedPly, setSelectedPly] = useState("");
  const [selectedSpecVirgin, setSelectedSpecVirgin] = useState("");
  const [selectedSpecSemi, setSelectedSpecSemi] = useState("");
  const [selectedNoOfSpec, setSelectedNoOfSpec] = useState("");
  const [selectedNoOfSpecSemi, setSelectedNoOfSpecSemi] = useState("");

  const [selectedProduct, setSelectedProduct] = useState("");

  const [globalConstraintsObj, setGlobalConstraintsObj] = useState({});
  const [companyName, setCompanyName] = useState("");

  const plySpecsArr = plySpecArr;

  const [plyDisplayArr, setPlyDisplayArr] = useState([]);
  const [specVirginDisplayArr, setSpecVirginDisplayArr] = useState([]);
  const [specSemiDisplayArr, setSpecSemiDisplayArr] = useState([]);
  const roundTill2points = (value) => {
    return Math.round(value * 10000) / 10000;
  };
  const getQuote = () => {
    let L = parseInt(length);
    let W = parseInt(width);
    let H = parseInt(height);
    console.log(L, W, H);
    let A = roundTill2points((W + H + 15) / 25.4);
    let A_round = Math.ceil(A);
    console.log(A, A_round, "A,A_round");
    let B = roundTill2points((2 * L + 2 * W + 80) / 25.4);
    let B_round = Math.ceil(B);
    console.log(B, B_round, "B,B_round");

    let ply = 3;
    if (selectedPly) {
      ply = parseInt(selectedPly);
    }

    let specVirgin = 180;
    if (selectedSpecVirgin) {
      specVirgin = parseInt(selectedSpecVirgin);
    }

    let NoOfSpec = 2;
    if (selectedNoOfSpec) {
      NoOfSpec = parseInt(selectedNoOfSpec);
    }

    let plySpecCheck = plySpecsArr.find((el) => el.ply == ply);
    if (plySpecCheck) {
      NoOfSpec = plySpecCheck.specVirgin;
    }
    let global_cost_virgin = 48;
    if (globalConstraintsObj.costVirgin && !isNaN(globalConstraintsObj.costVirgin)) {
      global_cost_virgin = parseInt(globalConstraintsObj.costVirgin);
    }

    let cost_virgin = roundTill2points(((A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97) * global_cost_virgin);
    console.log(cost_virgin, "cost_virgin");
    let semiSpec = 120;
    if (selectedSpecSemi) {
      semiSpec = parseInt(selectedSpecSemi);
    }
    let numberOfSpecSemi = 1;
    console.log(selectedNoOfSpecSemi, "selectedNoOfSpecSemi");
    if (selectedNoOfSpecSemi) {
      numberOfSpecSemi = parseInt(selectedNoOfSpecSemi);
    }
    if (plySpecCheck) {
      numberOfSpecSemi = plySpecCheck.specSemi;
    }
    // semiSpec = semiSpec * numberOfSpecSemi
    let semiSpecUsed = semiSpec * 1.5;

    let global_cost_semi = 41;
    if (globalConstraintsObj.costSemi && !isNaN(globalConstraintsObj.costSemi)) {
      global_cost_semi = parseInt(globalConstraintsObj.costSemi);
    }
    let cost_semi = roundTill2points(((A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97) * global_cost_semi);
    console.log(cost_semi, "cost_semi");
    let grossWeight = roundTill2points((A_round * B_round * specVirgin * NoOfSpec) / 1560000 / 0.97 + (A_round * B_round * semiSpecUsed * numberOfSpecSemi) / 1560000 / 0.97);
    console.log(grossWeight, "grossWeight");
    let totalCostPaper = roundTill2points(cost_semi + cost_virgin);
    console.log(totalCostPaper, "totalCostPaper");
    let conversionCost = roundTill2points(grossWeight * 9.5);
    console.log(conversionCost, "conversionCost");
    let totalCost = totalCostPaper + conversionCost;
    console.log(totalCost, "totalCost");
    let finalCost = totalCost;
    finalCost = Math.ceil(finalCost);

    console.log(finalCost, "finalCost");
    let percentage = (5 / 100) * finalCost;
    console.log(percentage, "percentage");
    setLowerLimit(roundTill2points(finalCost));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let obj = {
        // userId: loggedInUser._id,
        // productId: productObj._id,
        name,
        email,
        phone,
        message,
        length: checkSelectedCategory() ? parseInt(length) : parseInt(stickerlength),
        breadth: checkSelectedCategory() ? parseInt(width) : parseInt(stickerwidth),
        height: checkSelectedCategory() ? parseInt(height) : 0,
        ply: selectedPly ? selectedPly : 3,
        specVirgin: selectedSpecVirgin ? selectedSpecVirgin : 180,
        semiSpec: selectedSpecSemi ? selectedSpecSemi : 120,
        // NoOfSpec: selectedNoOfSpec ? selectedNoOfSpec : 2,
        // numberOfSpecSemi: selectedNoOfSpecSemi ? selectedNoOfSpecSemi : 1,
        isBox: checkSelectedCategory(),
        company: companyName,
      };
      let { data: res } = await addEnquiry(obj);
      console.log("this is res>>", res);
      if (res) {
        Swal.fire({
          title: "Success",
          type: "success",
          icon: "success",
          text: `${res.message}`,
        });
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setWidth(0);
        setHeight(0);
        setLength(0);
        setUpperLimit(0);
        setLowerLimit(0);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  const handleEcomEnquiry = async (e) => {
    e.preventDefault();

    try {
      let obj = {
        productName: "",
        range: 0,
        name,
        email,
        phone,
        message,
        company: companyName,
      };
      console.log(obj, "sent obj");
      let { data: res } = await addEcomEnquiry(obj);
      console.log("this is res>>", res);
      if (res) {
        Swal.fire({
          title: "Success",
          type: "success",
          icon: "success",
          text: `${res.message}`,
        });
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setWidth(0);
        setHeight(0);
        setLength(0);
        setUpperLimit(0);
        setLowerLimit(0);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  // function onlyUnique(value, index, self) {
  //     return self.findIndex(el => el.ply == value.ply) === index;
  // }

  const getPly = async () => {
    try {
      const { data: res } = await getAllPly();
      if (res) {
        let tempArr = [...res.data];
        console.log(tempArr);
        setPlyDisplayArr([...res.data.filter((el, i, arr) => arr.findIndex((elx) => elx.ply == el.ply) == i)]);
        setSpecVirginDisplayArr([...res.data.filter((el, i, arr) => arr.findIndex((elx) => elx.specVirgin == el.specVirgin) == i)]);
        setSpecSemiDisplayArr([...res.data.filter((el, i, arr) => arr.findIndex((elx) => elx.specSemi == el.specSemi) == i)]);
        console.log([...res.data.filter((el, i, arr) => arr.findIndex((elx) => elx.specSemi == el.specSemi) == i)]);
        setPlyArr(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const getCategory = async () => {
  //   try {
  //     const { data: res } = await getAllCategory();
  //     if (res) {
  //       setCategoryArr(res.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getGlobalConstraint = async () => {
    try {
      const { data: res } = await getAllglobalConstraints();
      if (res) {
        if (res.data && res.data.length > 0) {
          setGlobalConstraintsObj(res.data[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleGetAllProducts = async () => {
  //   try {
  //     let { data: res } = await getAllProducts();
  //     console.log("this is res>>", res);
  //     if (res) {
  //       setProductArr(res.data);
  //     }
  //   } catch (err) {
  //     if (err?.response?.data?.message) {
  //       console.error(err.response.data.message);
  //       Swal.fire({
  //         title: "Error",
  //         icon: "error",
  //         text: `${err.response.data.message}`,
  //       });
  //     } else {
  //       console.error(err);
  //       Swal.fire({
  //         title: "Error",
  //         icon: "error",
  //         text: `${err}`,
  //       });
  //     }
  //   }
  // };

  const checkSelectedCategory = () => {
    if (selectedProduct) {
      let selectedProductIndex = productArr.findIndex((item) => item.categoryId === selectedProduct);
      if (selectedProductIndex != -1) {
        if (productArr[selectedProductIndex].categoryName.toLowerCase().includes("box")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    handleGetAllProducts();
    getPly();
    getGlobalConstraint();
    getCategory();
  }, []);

  var settings = {
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
  };

  const getQuoteStickers = () => {
    let L = parseInt(stickerlength);
    let W = parseInt(stickerwidth);
    console.log(L, W);
    let paper_size = (L / 0.95) * (W / 0.95);
    console.log(paper_size, "paper_size");

    let costOfStickers = 29;
    if (globalConstraintsObj?.costOfStickers && !isNaN(globalConstraintsObj?.costOfStickers)) {
      costOfStickers = globalConstraintsObj?.costOfStickers;
    }
    let paper_cost = (paper_size / 1000000) * costOfStickers * 1000;
    console.log(paper_cost, "paper_cost");

    let conversionCost = paper_cost * 0.4;
    let finalCost = paper_cost + conversionCost;
    console.log(finalCost);
    let percentage = (5 / 100) * finalCost;
    // console.log(percentage, "percentage")
    setLowerLimit(roundTill2points(finalCost));
    setUpperLimit(roundTill2points(finalCost + percentage));
  };

  const checkWeatherBoxOrNot = (name) => {
    // console.log(name)
    // return true
    console.log(name?.toLowerCase().includes("box"), "name");
    return name?.toLowerCase().includes("box");
    // return name.toLowerCase().includes("box")
  };

  const checkCalculateOrNot = () => {
    if (selectedProduct) {
      let selectedProductIndex = productArr.findIndex((item) => item.categoryId === selectedProduct);
      if (selectedProductIndex != -1) {
        if (productArr[selectedProductIndex].categoryName.toLowerCase().includes("box") || productArr[selectedProductIndex].categoryName.toLowerCase().includes("sticker")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    getQuoteStickers();
  }, [stickerlength, stickerwidth]);

  useEffect(() => {
    getQuote();
  }, [length, width, height, selectedPly, selectedSpecSemi, selectedSpecVirgin]);

  return (
    <>
      <div id="myHeaderId"  className="section-fluid  d-none d-xl-block customHeader sticky-top ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-2">
              <div className="header-logo">
                <a href="/">
                  <img src={logo} alt="JSH Logo" />
                </a>
              </div>
            </div>

            <div className="col-10">
              <nav className="site-main-menu site-main-menu-left menu-height-100 justify-content-right">
                <ul>
                  <li>
                    <a href="/">
                      <span className="menu-text">Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <span className="menu-text">About us</span>
                    </a>
                  </li>
                  <li className="has-children">
                    <a href="/products">
                      <span className="menu-text">Customised Products</span> <i className="fa-solid fa-chevron-down ms-1 my-auto" style={{ color: "white" }}></i>
                    </a>
                    <ul className="sub-menu" style={{ backgroundColor: "#021126" }}>
                      {categoryArr?.map((product) => {
                        return (
                          <>
                            <li key={product._id}>
                              <a href={`/products?categoryId=${product._id}`}>
                                <span className="menu-text" style={{ color: "white" }}>
                                  {product?.name}
                                </span>
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="has-children">
                    {otherCategoryArr
                      .filter((el) => el.name == "Packing Accessories")
                      .map((el) => {
                        return (
                          <a href={`/products?categoryId=${el._id}&categoryName=${el.name}`}>
                            <span className="menu-text">{el.name}</span> <i className="fa-solid fa-chevron-down ms-1 my-auto" style={{ color: "white" }}></i>
                          </a>
                        );
                      })}
                    <ul className="sub-menu" style={{ backgroundColor: "#021126" }}>
                      {otherCategoryArr
                        .filter((el) => el.name != "Packing Accessories")
                        ?.map((product) => {
                          return (
                            <>
                              <li key={product._id}>
                                <a href={`/products?categoryId=${product._id}&categoryName=${product.name}`}>
                                  <span className="menu-text" style={{ color: "white" }}>
                                    {product?.name}
                                  </span>
                                </a>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  </li>
                  {/* {otherCategoryArr.filter(el => el.name == "Packing Accessories").map(el => {
                                        return (
                                            <li key={el._id}><a href={`/products?categoryId=${el._id}`}><span className="menu-text">{el?.name}</span></a></li>
                                        )
                                    })} */}
                  <li>
                    <a href="/process">
                      <span className="menu-text">Our Process</span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="menu-text">Contact Us</span>
                    </a>
                  </li>
                </ul>
                <div className="d-flex justify-content-center">
                  {isAuthorized ? (
                    <>
                      <a href="/orders" className="btn btn-primary  btn-md btn-pad me-3">
                        My Orders
                      </a>
                      <a onClick={handleSignout} className="btn btn-dark btn-hover-primary btn-md">
                        Sign Out
                      </a>
                    </>
                  ) : (
                    <>
                      <a href="/register" className="btn btn-primary  btn-md btn-pad me-3">
                        Register
                      </a>
                      <a href="/login" className="btn btn-dark btn-hover-primary btn-md" style={{ backgroundColor: "white", color: "#F8796C" }}>
                        Login
                      </a>
                    </>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="sticky-header section customHeader section-fluid d-none d-xl-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-auto col">
              <div className="header-logo">
                <a href="/">
                  <img src="../assets/images/logo/logo.png" alt="Learts Logo" />
                </a>
              </div>
            </div>

            <div className="col-auto ms-auto d-none d-xl-block">
              <nav className="site-main-menu site-main-menu-left justify-content-center">
                <ul>
                  <li>
                    <a href="/">
                      <span className="menu-text">Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <span className="menu-text">About us</span>
                    </a>
                  </li>
                  <li className="has-children">
                    <a href="/products">
                      <span className="menu-text">Products</span>
                    </a>
                    <ul className="sub-menu">
                      <li>
                        <a href="/product-details">
                          <span className="menu-text">Corrogated boxes</span>
                        </a>
                      </li>
                      <li>
                        <a href="/product-details">
                          <span className="menu-text">Mono boxes</span>
                        </a>
                      </li>
                      <li>
                        <a href="/product-details">
                          <span className="menu-text">Label stickers</span>
                        </a>
                      </li>
                      <li>
                        <a href="/product-details">
                          <span className="menu-text">Paper sheets</span>
                        </a>
                      </li>
                      <li>
                        <a href="/product-details">
                          <span className="menu-text">Mono cartons</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/process">
                      <span className="menu-text">Our Process</span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="menu-text">Contact Us</span>
                    </a>
                  </li>
                </ul>
                <div className="d-flex justify-content-center">
                  <a href="/register" className="btn btn-primary btn-md me-3">
                    Register
                  </a>
                  <a href="/login" className="btn btn-dark btn-hover-primary btn-md">
                    Login
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-header customHeader section d-none d-xl-none">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <div className="header-logo">
                <a href="/">
                  <img src="../assets/images/logo/logo.png" alt="JSH Logo" />
                </a>
              </div>
            </div>

            <div className="col-auto">
              <div className="header-tools justify-content-end">
                <div className="mobile-menu-toggle">
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-header sticky-header customHeader  section d-xl-none">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <div className="header-logo">
                <a href="/">
                  <img src="../assets/images/logo/logo-2.webp" alt="Learts Logo" />
                </a>
              </div>
            </div>

            <div className="col-auto">
              <div className="header-tools justify-content-end">
                <div className="header-login d-none d-sm-block">
                  <a href="my-account.html">
                    <i className="fal fa-user"></i>
                  </a>
                </div>
                <div className="header-search d-none d-sm-block">
                  <a href="#offcanvas-search" className="offcanvas-toggle">
                    <i className="fal fa-search"></i>
                  </a>
                </div>
                <div className="header-wishlist d-none d-sm-block">
                  <a href="#offcanvas-wishlist" className="offcanvas-toggle">
                    <span className="wishlist-count">3</span>
                    <i className="fal fa-heart"></i>
                  </a>
                </div>
                <div className="header-cart">
                  <a href="#offcanvas-cart" className="offcanvas-toggle">
                    <span className="cart-count">3</span>
                    <i class="fa-solid fa-cart-shopping"></i>
                  </a>
                </div>
                <div className="mobile-menu-toggle">
                  <a href="#offcanvas-mobile-menu" className="offcanvas-toggle">
                    <svg viewBox="0 0 800 600">
                      <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" className="top"></path>
                      <path d="M300,320 L540,320" className="middle"></path>
                      <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" className="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

<div className="mobile_navbarcanvastoggle d-xl-none d-lg-block d-sm-block d-md-block customHeader sticky-top">
<div className="container-fluid">
  <div className="row align-items-center">
    <div className="col-lg-6 col-6 col-sm-6">
        <div class="header-logo"><a href="/"><img src="../assets/images/logo/logo.png" alt="JSH Logo" /></a></div>
    </div>
    <div className="col-lg-6 col-6 col-sm-6 text-end">
      <a className="toggle" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"><i class="fa-solid fa-bars"></i></a>
    </div>
  </div>
  <div className="col-lg-12">
  <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h2 class="offcanvas-title" id="offcanvasExampleLabel">JSH</h2>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
  <nav className="site-main-menu site-main-menu-left justify-content-center">
                <ul>
                  <li>
                    <a href="/">
                      <span className="menu-text">Home</span>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <span className="menu-text">About us</span>
                    </a>
                  </li>
                 
                   <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Customised Products</Accordion.Header>
                      <Accordion.Body>
                      <div className="list_name">
                      <ul>
                      {categoryArr?.map((product) => {
                        return (
                          <>
                            <li key={product._id}>
                              <a href={`/products?categoryId=${product._id}`}>
                                <span className="menu-text" style={{ color: "white" }}>
                                  {product?.name}
                                </span>
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                      </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Packing Accessories</Accordion.Header>
                      <Accordion.Body>
                      <div className="list_name">
                      <ul>
                      {otherCategoryArr
                        .filter((el) => el.name != "Packing Accessories")
                        ?.map((product) => {
                          return (
                            <>
                              <li key={product._id}>
                                <a href={`/products?categoryId=${product._id}&categoryName=${product.name}`}>
                                  <span className="menu-text" style={{ color: "white" }}>
                                    {product?.name}
                                  </span>
                                </a>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                      </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <li>
                    <a href="/process">
                      <span className="menu-text">Our Process</span>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span className="menu-text">Contact Us</span>
                    </a>
                  </li>
                </ul>
                <div className="btnmobile d-flex">
                  <a href="/register" className="btn btn-primary btn-md me-3">
                    Register
                  </a>
                  <a href="/login" className="btn btn-dark btn-hover-primary btn-md">
                    Login
                  </a>
                </div>
              </nav>
  </div>
</div>
  </div> 
</div>

</div>

    










      <a id="scrollUp" href="#top" style={{ position: "fixed", zIndex: "2147483647" }}>
        <i className="fa-solid fa-arrow-up"></i>
      </a>
      <section id="chatBtn" className="suggestion-form">
        {/* Enquiry FORM starts here */}
        <div id="chatForm" className="form">
          <div className="row">
            <div className="col-sm-12 col-lg-12 my-3 ">
              <div className="contact-form">
                <h2 className="title ">Enquire Now</h2>
                <form className="homeEnquiryForm p-0" action="assets/php/contact-mail" id="contact-form" method="post">
                  <div className="row">
                    <div className="col-12 learts-mb-10">
                      <input className="homeEnqInput" value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Your Name *" name="name" />
                    </div>
                    <div className="col-12 learts-mb-30">
                      <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} type="text" placeholder="Company Name*" name="name" />
                    </div>

                    <div className="col-md-6 col-12 learts-mb-10">
                      <input className="homeEnqInput" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email *" name="email" />
                    </div>
                    <div className="col-md-6 col-12 learts-mb-10">
                      <input className="homeEnqInput" value={phone} maxLength={10} onChange={(e) => setPhone(e.target.value)} type="tel" placeholder="Phone No. *" name="phone number" />
                    </div>

                    <div className="col-md-12 col-12 learts-mb-10">
                      <div className="slidecontainer row d-flex align-items-baseline justify-content mt-4">
                        <div className="col-12" style={{ display: "flex", flexDirection: "column" }}>
                          <label className="text-start mt-2 text-nowrap" htmlFor="">
                            Category
                          </label>
                          <select
                            placeholder="Select Product"
                            className="form-control ml-4"
                            onChange={(e) => {
                              setSelectedProduct(e.target.value);
                              // console.log(
                              //   "selecteddd",
                              //   e.options[e.selectedIndex].text
                              // );
                              // setSelectedProductName(e.target.name);
                            }}
                          >
                            <option>Select Category</option>
                            {categoryArr.map((el) => {
                              return (
                                <option key={el._id} value={el._id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {checkCalculateOrNot() && (
                          <>
                            {checkSelectedCategory() ? (
                              <>
                                <div className="col-4 learts-mb-10 mt-2">
                                  <div className="slidecontainer d-flex align-items-baseline">
                                    <label className="text-start mb-0 text-nowrap" htmlFor="">
                                      Length(mm)
                                    </label>
                                  </div>
                                  <input
                                    type="tel"
                                    minLength="1"
                                    maxLength="5"
                                    value={length}
                                    onChange={(e) => {
                                      setLength(e.target.value);
                                    }}
                                    className="slider homeEnqInput"
                                  />
                                </div>
                                <div className="col-4 mt-2">
                                  <div className="slidecontainer d-flex align-items-baseline">
                                    <label className="text-start mb-0 text-nowrap" htmlFor="">
                                      Width:(mm)
                                    </label>
                                  </div>
                                  <input
                                    type="tel"
                                    minLength="1"
                                    maxLength="5"
                                    value={width}
                                    onChange={(e) => {
                                      setWidth(e.target.value);
                                    }}
                                    className="slider homeEnqInput"
                                  />
                                </div>

                                <div className="col-4 mt-2">
                                  <div className="slidecontainer d-flex align-items-baseline">
                                    <label className="text-start mb-0 text-nowrap" htmlFor="">
                                      Height(mm)
                                    </label>
                                  </div>
                                  <input
                                    type="tel"
                                    minLength="1"
                                    maxLength="5"
                                    value={height}
                                    onChange={(e) => {
                                      setHeight(e.target.value);
                                    }}
                                    className="slider homeEnqInput"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-4 mt-2 learts-mb-10">
                                  <div className="slidecontainer d-flex align-items-baseline">
                                    <label className="text-start mb-0 text-nowrap" htmlFor="">
                                      Length(mm)
                                    </label>
                                  </div>
                                  <input
                                    type="tel"
                                    minLength="1"
                                    maxLength="5"
                                    value={stickerlength}
                                    onChange={(e) => {
                                      setStickerLength(e.target.value);
                                    }}
                                    className="slider homeEnqInput"
                                  />
                                </div>
                                <div className="col-4 mt-2">
                                  <div className="slidecontainer d-flex align-items-baseline">
                                    <label className="text-start mb-0 text-nowrap" htmlFor="">
                                      Width:(mm)
                                    </label>
                                  </div>
                                  <input
                                    type="tel"
                                    minLength="1"
                                    maxLength="5"
                                    value={stickerwidth}
                                    onChange={(e) => {
                                      setStickerWidth(e.target.value);
                                    }}
                                    className="slider homeEnqInput"
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {checkCalculateOrNot() && checkSelectedCategory() && (
                          <>
                            <div
                              className="col-4"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label className="text-start mt-2 text-nowrap" htmlFor="">
                                Ply
                              </label>

                              <select placeholder="Select Product" className="form-control" onChange={(e) => setSelectedPly(e.target.value)}>
                                <option>Ply</option>
                                {plyDisplayArr.map((el) => {
                                  return (
                                    <option key={el._id} value={el.ply}>
                                      {el.ply}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div
                              className="col-4"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label className="text-start mt-2 text-nowrap" htmlFor="">
                                Spec Virgin
                              </label>

                              <select placeholder="Select Product" className="form-control" onChange={(e) => setSelectedSpecVirgin(e.target.value)}>
                                <option>Spec Virgin</option>
                                {specVirginDisplayArr.map((el) => {
                                  return (
                                    <option key={el._id} value={el.specVirgin}>
                                      {el.specVirgin}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div
                              className="col-4"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label className="text-start mt-2 text-nowrap" htmlFor="">
                                Spec Semi
                              </label>

                              <select placeholder="Select Product" className="form-control" onChange={(e) => setSelectedSpecSemi(e.target.value)}>
                                <option>Semi Spec </option>
                                {specSemiDisplayArr.map((el) => {
                                  return (
                                    <option key={el._id} value={el.specSemi}>
                                      {el.specSemi}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {checkCalculateOrNot() && (
                      <div className="col-12 text-start total-amount">
                        <label>Upper Limit and Lower Limit</label>
                        <p className="m-0">
                          <b>₹ {lowerLimit}</b> <span style={{ fontWeight: "normal" }}>&nbsp;To&nbsp;</span>
                          <b>₹ {upperLimit}</b>
                        </p>
                        {selectedProduct === "62454c25762130914b37faed" && <p>For 1000 stickers</p>}
                      </div>
                    )}
                    <div className="col-12 ">
                      <textarea className="homeEnqInput" style={{ height: "100px" }} value={message} onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Message" spellCheck="false" defaultValue={""} />
                    </div>
                    {checkCalculateOrNot() ? (
                      <div className="col-12 mt-3">
                        <button
                          onClick={handleSubmit}
                          className="btn btn-dark btn-outline-hover-dark"
                          style={{
                            backgroundColor: "#F8796C",
                            borderColor: "#F8796C",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    ) : (
                      <div className="col-12 mt-3">
                        <button
                          onClick={handleEcomEnquiry}
                          className="btn btn-dark btn-outline-hover-dark"
                          style={{
                            backgroundColor: "#F8796C",
                            borderColor: "#F8796C",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </form>
                <p className="form-messege" />
              </div>
            </div>
          </div>
        </div>

        {/* Commented any suggestion form */}

        {/* <div id="chatForm" className="form">
          <h2 className="title">Any Suggestions?</h2>
          <div className="contact-form">
            <form action="#" id="contact-form" method="post">
              <div className="row">
                <div className="col-12">
                  <input type="text" placeholder="Your Name *" name="name" />
                </div>
                <div className="col-md-6 col-12">
                  <input type="email" placeholder="Email *" name="email" />
                </div>
                <div className="col-md-6 col-12">
                  <input type="tel" placeholder="Phone No. *" name="phone number" />
                </div>
                <div className="col-12">
                  <textarea name="message" placeholder="Message" spellCheck="false" defaultValue={""} />
                </div>
                <div className="col-12">
                  <button className="btn btn-dark btn-md mt-4 btn-outline-hover-dark">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <div>{/* <i onClick={handleForm} style={{color:'red',fontSize:120 }}  class="fa-thin fa-comments"></i> */}</div>
        <img onClick={handleForm} src="./assets/images/suggestion.png" alt="" />
      </section>
    </>
  );
}
