import React from 'react'

export default function Process() {
    return (
        <>
            <main className="">
                {/* Page Title/Header Start */}
                <div className="section section-fluid">
                    <div className="container-fluid">
                        <div className="page-title-section" data-bg-image="./assets/images/packages/10.jpeg">
                            <div className="row">
                                <div className="col">
                                    <div className="page-title">
                                        <h1 className="title">Our Process</h1>
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active">Our Process</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Title/Header End */}
                <div className="section section-padding pb-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 align-self-center">
                                <div className="about-us4">
                                    <h2 className="title">Quality Credential AND Certifications</h2>
                                    <p>We have a fully equipped lab where we are able to control the quality at each stage of production It is fully equipped with the following testing equipment:</p>
                                </div>
                            </div>
                        </div>
                        <div className="row g-0 row-cols-md-3 row-cols-1">
                            <div className="icon-box3 col">
                                <div className="inner">
                                    <div className="content">
                                        <h6 className="title m-0">Weight Testing Machine for Gram per Square Meter (GSM) </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-box3 col">
                                <div className="inner">
                                    <div className="content">
                                        <h6 className="title m-0">Bursting Strength Testing Machine.</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-box3 col">
                                <div className="inner">
                                    <div className="content">
                                        <h6 className="title m-0">Moisture Testing Machine</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-box3 col">
                                <div className="inner">
                                    <div className="content">
                                        <h6 className="title m-0">Cobb Value Testing Machine</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-box3 col">
                                <div className="inner">
                                    <div className="content">
                                        <h6 className="title m-0">Compression Test Machine</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section test-performed section-padding pt-0">
                    <div className="container">
                        <h4 className="title">We perform the following Test:</h4>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <ul className="test-box">
                                    <li>Incoming Material tests:
                                        <ul>
                                            <li>Weight of the reel</li>
                                            <li>Colour of the paper</li>
                                            <li>G.S.M. of the paper</li>
                                            <li>Bursting strength</li>
                                            <li>Moisture &amp; Winding of Reels</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-4">
                                <ul className="test-box">
                                    <li>Work-In-Progress:
                                        <ul>
                                            <li>G.S.M. of the board</li>
                                            <li>Bursting strength of the Board</li>
                                            <li>Moisture content of the Board</li>
                                            <li>Edge crush test (ECT)</li>
                                            <li>Bending stiffness </li>
                                            <li>Printability - Test of ink rub resistance </li>
                                            <li>Friction</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-4">
                                <ul className="test-box">
                                    <li>Final Goods:
                                        <ul>
                                            <li>B.C.T. of the box</li>
                                            <li>Rough handling protocol</li>
                                            <li>Transport test</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row learts-mb-n30 mobilemb-4">
                            <div className="col-12 learts-mb-30">
                                <div className="sale-banner7">
                                    <div className="inner">
                                        <div className="image"><img src="assets/images/about/1.jpg" alt="Sale Banner Image" /></div>
                                        <div className="content">
                                            <h3 className="title">Bending stiffness</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-12 learts-mb-30 ">
                                <div className="sale-banner7">
                                    <div className="inner">
                                        <div className="image"><img src="assets/images/about/2.jpg" alt="Sale Banner Image" /></div>
                                        <div className="content">
                                            <h3 className="title">Printability</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-12 learts-mb-30">
                                <div className="sale-banner7">
                                    <div className="inner">
                                        <div className="image"><img src="assets/images/about/3.jpg" alt="Sale Banner Image" /></div>
                                        <div className="content">
                                            <h3 className="title">B.C.T. of the box</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-12 learts-mb-30">
                                <div className="sale-banner7">
                                    <div className="inner">
                                        <div className="image"><img src="assets/images/about/4.jpg" alt="Sale Banner Image" /></div>
                                        <div className="content">
                                            <h3 className="title">Transport test</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section certified section-padding pt-0">
                    <div className="container">
                        <h4 className="title text-center">We have also been certified with the following Quality certificates:</h4>
                        <div className="row row-cols-lg-4 row-cols-sm-2 row-cols-1 justify-content-center">
                            <div className="col">
                                <div className="category-banner5">
                                    <div className="inner">
                                        <div className="image"><img src="assets/images/certified.png" alt /></div>
                                        <div className="content">
                                            <h3 className="title">(NOIDA) ISO 9001:2015 </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="category-banner5">
                                    <div className="inner">
                                        <div className="image"><img src="assets/images/certified.png" alt /></div>
                                        <div className="content">
                                            <h3 className="title">(BAWAL) ISO 9001:2015 </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="category-banner5">
                                    <div className="inner">
                                        <div className="image"><img src="assets/images/certified.png" alt /></div>
                                        <div className="content">
                                            <h3 className="title">(HARIDWAR) ISO 9001:2015 </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section machine-capability section-padding pt-0">
                    <div className="container">
                        <h4 className="title">MACHINES AND PRODUCTION CAPABILIES</h4>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <ul>
                                    {/* <li>High speed Corrugation M/c : <span>18</span></li> */}
                                    <li>Five ply automatic box manufacturing plant :<span>62" and 82"</span></li>
                                    <li>Paper Board Cutters : <span>15</span></li>
                                    <li>Pasting Machines : <span>12</span></li>
                                    <li>Rotary Machine : <span>10</span></li>
                                    <li>Pasting and Slot Cutting Machine : <span>8</span></li>
                                    <li>Die Cutting Machines : <span>21</span></li>
                                    <li>High speed Corrugation M/c : <span>08</span></li>
                                    <li>Automatic stitching Machine : <span>02</span></li>
                                    <li>Auto Gluer : <span>01</span></li>
                                    <li>Stitching Machine : <span>32</span></li>
                                    <li>Lamination Machine : <span>02</span></li>
                                    <li>Board Printer : <span>02</span></li>
                                    {/* <li>Stitching Machines : <span>32</span></li> */}
                                </ul>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src="./assets/images/packages/8.jpeg" alt />
                                <img src="./assets/images/packages/dark_bg_img.png" alt />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section machines-text porssermobilebottom section-padding pt-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <h4 className="title">CORRUGATOR</h4>
                                <p>State of the art automatic box manufacturing machines.able to run 23”-82”wide rolls.
                                </p>
                                <ul>
                                    <li>Run single wall flutes: C, B, D, E</li>
                                    <li>Run double wall flutes: BC, BD, ED</li>
                                    <li>Run pre-print with cut-to-mark capabilities at the cut off knife</li>
                                </ul>
                                <p>Our auto-maticpresses are capable of running boxes in up to two colors from a minimum size of 3” x 3” x 4” (sheet size 7” x 12”) to a maximum size of 40” x 40” x 40” (sheet size 108” x 164”) – and any standard or custom size in between
                                    to large bundle packing of any vehicle.</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src="./assets/images/about/5.jpg" alt />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section machines-text porssermobilebottom section-padding pt-0 rowreverse_mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <img src="./assets/images/about/6.jpg" alt />
                            </div>
                            <div className="col-12 col-md-6">
                                <h4 className="title">PRINTING</h4>
                                <ul>
                                    <li>2-color flexo printing with inline die cutting and two full slotting sections.size:82”-164”.</li>
                                    <li>2-color flexo printing with inline die cutting for high speed production.size:44”-92”.</li>
                                    <li>2-color flexo printing with inline die cutting and two full slotting sections.size:62”-124”.</li>
                                    <li>2-color flexo printing with inline die cutting for high speed production.size:42”-88”.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section machines-text porssermobilebottom section-padding pt-0 ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <h4 className="title">DIE-CUTTING</h4>
                                <ul>
                                    <li>62”-105” machine one of the largest die cutting machine in India.</li>
                                    <li>58”-78” machine for special cartons</li>
                                    <li>56”-72” machine for special cartons</li>
                                    <li>36”-48” Hi-speed die cutting machine</li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src="./assets/images/about/7.jpg" alt />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section machines-text porssermobilebottom section-padding pt-0 rowreverse_mobile">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6">
                                <img src="./assets/images/about/8.jpeg" alt />
                            </div>
                            <div className="col-12 col-md-6">
                                <h4 className="title">FINISHING &amp; SPECIALTY</h4>
                                <p>Stitching machine: Joints that require staples instead of glue, this machine can do medium to jumbo sizes, including 1-piece and 2-piece stitch work. TAPER/GLUER: Glue/tape and fold center glued cartons, die cut cartons, and regular
                                    cartons
                                </p>
                                <ul>
                                    <li><span>TRAY MACHINE:</span> Folding and gluing for specialty trays</li>
                                    <li><span>MANUAL SLITTER/SCORER:</span> For corrugator pad production</li>
                                    <li><span>BANDSAW:</span> For corrugator pad production</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
