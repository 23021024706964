import React from "react"; 

export default function Footer() {
  return (
    <>
      <div className="customHeader">
        <div className="footer2-section customHeader section learts-pt-50 learts-pb-50">
          <div className="container">
            <div className="row learts-mb-n40">
              <div className="col-lg-3 learts-mb-40">
                <div className="widget-about">
                
                  
                
                  <img src="static/media/JSH-Logo-Gif.7196dc78b02868f123ed.gif" alt /><br/>
                  <div class="row bottom-images">
                  <div className="col-lg-8 col-8">
                  <img src="../assets/images/makeInIndia.png" width="100%" alt />
                  </div>
                 <div className="col-lg-4 col-4">
                  <img src="../assets/images/recycle.png" alt />
                  </div>
                  </div>
                  {/* <p style={{color:'white'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod itaque recusandae commodi mollitia facere iure nisi, laudantium quis quas perferendis a minus dolores.</p> */}
                </div>
              </div>
              <div className="col-lg-2">
                <h3>Quick Links</h3>
                  <div className="col">
                    <ul className="widget-list quicklinks">
                      <li>
                        <a href="/about" style={{ color: "#a5a5a5" }}>
                          <i class="fa-solid fa-angle-right"></i> About us
                        </a>
                      </li>
                      <li>
                        <a href="https://goo.gl/maps/nuGmrMRJxr12XXLa8" style={{ color: "#a5a5a5" }}>
                          <i class="fa-solid fa-angle-right"></i> Store location
                        </a>
                      </li>
                      <li>
                        <a href="/contact" style={{ color: "#a5a5a5" }}>
                          <i class="fa-solid fa-angle-right"></i> Contact
                        </a>
                      </li>
                      <li>
                        <a href="#" style={{ color: "#a5a5a5" }}>
                          <i class="fa-solid fa-angle-right"></i> Orders
                        </a>
                      </li>
                      <li>
                        <a href="#" style={{ color: "#a5a5a5" }}>
                          <i class="fa-solid fa-angle-right"></i> Returns
                        </a>
                      </li>
                      <li>
                        <a href="#" style={{ color: "#a5a5a5" }}>
                          <i class="fa-solid fa-angle-right"></i> Support Policy
                        </a>
                      </li>
                      {/* <li><a href="#" style={{color:'#a5a5a5'}}>Size Guide</a></li> */}
                      <li>
                        <a href="#" style={{ color: "#a5a5a5" }}>
                          <i class="fa-solid fa-angle-right"></i> FAQs
                        </a>
                      </li>
                    </ul>
                  </div>
                  
                </div>
              <div className="col-lg-3 learts-mb-40 opening_hours_header">
                <h3>Opening Hours</h3>
                <ul class="opening-hours">
                    <li><i class="fa-solid fa-clock"></i> Monday: 10AM - 7PM</li>
                    <li><i class="fa-solid fa-clock"></i> Tuesday: 10AM - 7PM</li>
                    <li><i class="fa-solid fa-clock"></i> Wednesday: 10AM - 7PM</li>
                    <li><i class="fa-solid fa-clock"></i> Thursday: 10AM - 7PM</li>
                    <li><i class="fa-solid fa-clock"></i> Firday: 10AM - 7PM</li>
                    <li><i class="fa-solid fa-clock"></i> Saturday: 10AM - 7PM</li>
                    <li><i class="fa-solid fa-clock"></i> Sunday: Closed</li>
                    
                </ul>
              </div>
              <div className="col-lg-4 learts-mb-40">
              <h3>Contact Information</h3>
                <ul class="contact-details-footer">
                    <li><i class="fa-solid fa-location-dot"></i> F-128, F Block, Sector 8, Noida, Uttar Pradesh 201301</li>
                    <li><i class="fa-solid fa-envelope me-2"></i> enquiry@jshpackagings.com</li>
                    
                </ul>
                <h3>Follow Us</h3>
                <ul className="widget-list social-icons-footer">
                  <li>
                    {" "}
                    
                    <a href="https://www.twitter.com/" style={{ color: "white" }}>
                      <i className="fab fa-twitter" style={{ color: "white" }} />{" "}
                    </a>
                  </li>
                  <li>
                    {" "}
                    
                    <a href="https://www.facebook.com/" style={{ color: "white" }}>
                      <i className="fab fa-facebook-f" style={{ color: "white" }} />{" "}
                    </a>
                  </li>
                  <li>
                    {" "}
                    
                    <a href="https://www.instagram.com/" style={{ color: "white" }}>
                      <i className="fab fa-instagram" style={{ color: "white" }} />{" "}
                    </a>
                  </li>
                  <li>
                    {" "}
                    
                    <a href="https://www.linkedin.com/company/jsh-packagings/" style={{ color: "white" }}>
                      <i className="fab fa-linkedin-in" style={{ color: "white" }} />{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer2-copyright section">
          <div className="container">{ <p className="copyright text-center " style={{color:'white'}}>Copyright © 2023 JSH PACKAGINGS | All Rights Reserved.</p> }</div>
        </div>
        {/* Modal */}
        <div className="quickViewModal modal fade" id="quickViewModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <button className="close" data-bs-dismiss="modal">
                ×
              </button>
              <div className="row learts-mb-n30">
                {/* assets/images/packages/23.jpgct Images Start --&gt; */}
                <div className="col-lg-6 col-12 learts-mb-30">
                  <div className="product-images">
                    <div className="product-gallery-slider-quickview">
                      <div className="product-zoom" data-image="assets/images/product/single/1/product-zoom-1.webp">
                        <img src="assets/images/product/single/1/product-1.webp" alt />
                      </div>
                      <div className="product-zoom" data-image="assets/images/product/single/1/product-zoom-2.webp">
                        <img src="assets/images/product/single/1/product-2.webp" alt />
                      </div>
                      <div className="product-zoom" data-image="assets/images/product/single/1/product-zoom-3.webp">
                        <img src="assets/images/product/single/1/product-3.webp" alt />
                      </div>
                      <div className="product-zoom" data-image="assets/images/product/single/1/product-zoom-4.webp">
                        <img src="assets/images/product/single/1/product-4.webp" alt />
                      </div>
                    </div>
                  </div>
                </div>
                {/* assets/images/packages/24.jpgct Images End --&gt; */}
                {/* Product Summery Start */}
                <div className="col-lg-6 col-12 overflow-hidden position-relative learts-mb-30">
                  <div className="product-summery customScroll">
                    <div className="product-ratings">
                      <span className="star-rating">
                        <span className="rating-active" style={{ width: "100%" }}>
                          ratings
                        </span>
                      </span>
                      <a href="#reviews" className="review-link">
                        (<span className="count">3</span> customer reviews)
                      </a>
                    </div>
                    <h3 className="product-title">Cleaning Dustpan &amp; Brush</h3>
                    e – Hold the brush and dustpan together for storage; the dustpan edge is serrated to allow easy scraping off the hair without entanglement. High-quality bristles – no burr damage, no scratches, thick and durable,
                    comfortable to remove dust and smaller particles.
                    <p />
                  </div>
                  <div className="product-variations">
                    <table>
                      <tbody>
                        <tr>
                          <td className="label">
                            <span>Size</span>
                          </td>
                          <td className="value">
                            <div className="product-sizes">
                              <a href="#">Large</a>
                              <a href="#">Medium</a>
                              <a href="#">Small</a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <span>Color</span>
                          </td>
                          <td className="value">
                            <div className="product-colors">
                              <a href="#" data-bg-color="#000000" />
                              <a href="#" data-bg-color="#ffffff" />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <span>Quantity</span>
                          </td>
                          <td className="value">
                            <div className="product-quantity">
                              <span className="qty-btn minus">
                                <i className="ti-minus" />
                              </span>
                              <input type="text" className="input-qty" defaultValue={1} />
                              <span className="qty-btn plus">
                                <i className="ti-plus" />
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="product-buttons">
                    <a href="#" className="btn btn-icon btn-outline-body btn-hover-dark">
                      <i className="fal fa-heart" />
                    </a>
                    <a href="#" className="btn btn-dark btn-outline-hover-dark">
                      <i className="fal fa-shopping-cart" /> Add to Cart
                    </a>
                    <a href="#" className="btn btn-icon btn-outline-body btn-hover-dark">
                      <i className="fal fa-random" />
                    </a>
                  </div>
                  <div className="product-brands">
                    <span className="title">Brands</span>
                    <div className="brands">
                      <a href="#">
                        <img src="assets/images/brands/brand-3.webp" alt />
                      </a>
                      <a href="#">
                        <img src="assets/images/brands/brand-8.webp" alt />
                      </a>
                    </div>
                  </div>
                  <div className="product-meta mb-0">
                    <table>
                      <tbody>
                        <tr>
                          <td className="label">
                            <span>SKU</span>
                          </td>
                          <td className="value">0404019</td>
                        </tr>
                        <tr>
                          <td className="label">
                            <span>Category</span>
                          </td>
                          <td className="value">
                            <ul className="product-category">
                              <li>
                                <a href="#">Kitchen</a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <span>Tags</span>
                          </td>
                          <td className="value">
                            <ul className="product-tags">
                              <li>
                                <a href="#">handmade</a>
                              </li>
                              <li>
                                <a href="#">JSH</a>
                              </li>
                              <li>
                                <a href="#">mug</a>
                              </li>
                              <li>
                                <a href="#">product</a>
                              </li>
                              <li>
                                <a href="#">JSH</a>
                              </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <span>Share on</span>
                          </td>
                          <td className="va">
                            <div className="product-share">
                              <a href="#">
                                <i className="fab fa-facebook-f" />
                              </a>
                              <a href="#">
                                <i className="fab fa-twitter" />
                              </a>
                              <a href="#">
                                <i className="fab fa-google-plus-g" />
                              </a>
                              <a href="#">
                                <i className="fab fa-pinterest" />
                              </a>
                              <a href="#">
                                <i className="fal fa-envelope" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Product Summery End */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
