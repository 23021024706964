import React, { useState } from "react";
import Swal from "sweetalert2";
import { contact } from "../services/contact";

export default function Contact() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const handleContact = async (e) => {
    e.preventDefault();
    try {
      if (name == "") {
        alert("Please Enter Name");
      } else if (email == "") {
        alert("Please Enter Email");
      } else if (phone == "") {
        alert("Please Enter Phone");
      } else if (message == "") {
        alert("Please Enter Message");
      } else {
        let obj = {
          name,
          email,
          phone,
          message,
        };
        let { data: res } = await contact(obj);
        console.log("this is res>>", res);
        if (res) {
          Swal.fire({
            title: "Success",
            type: "success",
            icon: "success",
            text: `${res.message}`,
          });
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err.response.data.message);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err.response.data.message}`,
        });
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          icon: "error",
          text: `${err}`,
        });
      }
    }
  };

  return (
    <>
      <main className="customBackground">
        {/* Page Title/Header Start */}
        <div className="section section-fluid">
          <div className="container-fluid">
            <div
              className="page-title-section"
              style={{
                backgroundImage: "../assets/images/packages/Getintouch.jpg",
              }}
              data-bg-image="assets/images/packages/Getintouch.jpg"
            >
              <div className="row">
                <div className="col">
                  <div className="page-title">
                    <h1 className="title">Contact us</h1>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li className="breadcrumb-item active">Contact us</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page Title/Header End */}
        <div className="section section-padding">
          <div className="container">
            {/* Section Title Start */}
            <div className="section-title2 text-center">
              <h2 className="title">Get in touch with us</h2>
              {/* <p>Been tearing your hair out to find the perfect gift for your loved ones? Try visiting our nationwide local stores. You can also contact us to become partner or distributor. Call us, send us an email or make an appointment now.</p> */}
            </div>
            {/* Section Title End */}
            {/* Contact Information Start */}
            <div className="row learts-mb-n30">
              <div className="col-12 learts-mb-30">
                <div className="contact-info address-info">
                  <h4 className="title">ADDRESS</h4>
                  <div className="d-flex flex-wrap">
                    <span className="info">
                      {" "}
                      <b>
                        <i className="fa-solid fa-location-dot"></i> Corporate Office &amp; Unit-I:{" "}
                      </b>
                      <b>F-128, Sec-VIII, Noida-201301 (U.P.)</b>
                    </span>
                    <span className="info">
                      {" "}
                      <b>
                        <i className="fa-solid fa-location-dot"></i> Unit - II (Hardwar):{" "}
                      </b>
                      <b>Plot No. 47-48, SEC-V, I.I.E., Ranipur, SIDCUL, Hardwar (Uttarakhand)</b>
                    </span>
                    <span className="info">
                      {" "}
                      <b>
                        <i className="fa-solid fa-location-dot"></i> Unit-III (Bawal):{" "}
                      </b>
                      <b>Plot no. 60, Phase 11 and IV, Sector – 14, IMT BAWAL, Haryana</b>
                    </span>
                    <span className="info">
                      {" "}
                      <b>
                        <i className="fa-solid fa-location-dot"></i> Unit -IV ( Chennai):{" "}
                      </b>
                      <b>JH PACKAGINGS PRIVATE LTD. A-11, PILIIAPAKKAM INDUSTRIAL AREA SRIPERUMUDUR, CHENNAI</b>
                    </span>
                    <span className="info">
                      {" "}
                      <b>
                        <i className="fa-solid fa-location-dot"></i> Unit – V (Pune):{" "}
                      </b>
                      <b> Shed No. 1 Grampanchayat Milkat no 1115 Mahalunkar INDS Gate No. 201 Pune - 410501</b>{" "}
                    </span>
                    <span className="info">
                      {" "}
                      <b>
                        <i className="fa-solid fa-location-dot"></i> Unit – VI (Bawal Unit 2):{" "}
                      </b>
                      <b> PLOT NO 12 SECTOR-9 IMT BAWAL DISTT REWARI (HARYANA)</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Contact Information End */}
          </div>
        </div>
        <div className="section section-padding pt-0">
          <div className="container">
            <div className="row gap-3">
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12 learts-mb-30">
                    <div className="contact-info">
                      <h4 className="title">Phone No.</h4>
                      <span className="info">
                        <i className="fa-solid fa-phone me-2"></i>{" "}
                        <a href="tel:+91-97175-99456">
                          <b>Mobile: +91-97175-99456</b>
                        </a>
                      </span>
                      <span className="info">
                        <i className="fa-solid fa-phone me-2"></i>{" "}
                        <a href="tel:+91-98181-44024">
                          <b>Mobile: +91-98181-44024 </b>
                        </a>
                      </span>
                      <span className="info">
                        <i className="fa-solid fa-phone me-2"></i>{" "}
                        <a href="tel:+91-98101-31014">
                          <b>Mobile: +91-98101-31014</b>
                        </a>
                      </span>
                      <span className="info">
                        <i className="fa-solid fa-phone me-2"></i>{" "}
                        <a href="tel:+91-99906-98236">
                          <b>Mobile: +91-99906-98236</b>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="col-12 learts-mb-30">
                    <div className="contact-info">
                      <h4 className="title">Email Address</h4>
                      <span className="info">
                        <i className="fa-solid fa-envelope me-2"></i>
                        <b> Mail:</b>{" "}
                        <a href="mailto:enquiry@jshpackagings.com">
                          <b>enquiry@jshpackagings.com</b>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <ul className="widget-list d-flex align-items-center social-links">
                      <li>
                        <a href="https://www.twitter.com/">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li className="mt-0">
                        <a href="https://www.facebook.com/">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li className="mt-0">
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li className="mt-0">
                        <a href="https://www.youtube.com/">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="contact-sec bg-light">
                  {/* Section Title Start */}
                  <div className="section-title2">
                    <h2 className="title">Send a message</h2>
                  </div>
                  {/* Section Title End */}
                  <div className="contact-form">
                    <form action="assets/php/contact-mail" id="contact-form" method="post">
                      <div className="row learts-mb-n30">
                        <div className="col-12 learts-mb-30">
                          <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Your Name *" name="name" />
                        </div>
                        <div className="col-md-6 col-12 learts-mb-30">
                          <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email *" name="email" />
                        </div>
                        <div className="col-md-6 col-12 learts-mb-30">
                          <input value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" placeholder="Phone No. *" minLength={10} name="phone" />
                        </div>
                        <div className="col-12 learts-mb-30">
                          <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Message" spellCheck="false" defaultValue={""} />
                        </div>
                        <div className="col-12">
                          <button onClick={handleContact} className="btn btn-dark btn-outline-hover-dark">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                    <p className="form-messege" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Map Start */}
        <section className="section section-padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col">
                <iframe
                  src="https://www.google.com/maps/d/embed?mid=16XmWUSiOKZFCaB80En-8IwIJP2mK3Ys&ehbc=2E312F"
                  width={"100%"}
                  height="500px"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        {/* Contact Map End */}
      </main>
    </>
  );
}
